import React, { useContext } from "react";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { ThemePreferenceContext } from "../../../../index";
import "./MetricGraphs.css";
const Plot = createPlotlyComponent(Plotly);

export default function SessionsMaincomp(props) {
  console.log("vanteya")
  console.log("PROPS",props)
  const auth = useContext(AuthContext);
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const userId = auth.id || props.data.uid || props.uid;
  console.log("userID",userId)
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const width = props.try === "small" ? 450 : 600;
  // const width2 = props.try === "large" ? 800 : 600;

  React.useEffect(() => {
    const currentSessionData = JSON.parse(
      sessionStorage.getItem("currentSessionData")
    );
    if (currentSessionData) {
      axios
        .get("/sessionDetails", {
          params: {
            userId: userId || currentSessionData.user_id,
            DeviceId: global.deviceid || currentSessionData.device_id||props.data.device,
            appName: global.appname || currentSessionData.app_name||props.data.appName,
            sessionId: global.sessionid || currentSessionData.session_id||props.data.sessions_id,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          console.log("DATA CAPTURED")
          SetSecondsessiondata(res.data.data[0]);
        })
        .catch((err)=>{
          console.log("Error",err)
        });
    }
  }, []);

  const handleSelected = (event) => {
    props.senddata1(Secondsessiondata.gpu_usage_time[event.points[0].pointIndex], event.points[0].y);
  };

  return (
    <>
      <Plot
        data={[
          {
            // x: Secondsessiondata.gpu_usage_time,
            y: Secondsessiondata.cpu_app_usage,
            hovertemplate: "<b>CPU : %{y}(%)</b><br><b>Time : %{text}</b>",
            text: Secondsessiondata.gpu_usage_time,
            showlegend: false,
            type: "scatter",
            mode: "lines+markers",
            line: { shape: "spline", smoothing: 0.8 },
            marker: { color: "#FEC400", size: "0" },
          },
        ]}
        layout={{
          X: 0,
          xanchor: "left",
          height: 240,
          width: width,
          margin: { r: 40, b: 95, t: 15, pad: 1 },
          xref: 450,
          text: "CPU Usage",
          borderRadius: 20,
          xaxis: {
            gridcolor: "gray",
            zeroline: false,
            // zerolinecolor:currentTheme === "dark" ? "#FFFFFF" : "#000000",
            line: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
            tickfont: {
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            },
            linecolor: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            autorange: false,
            rangemode: "nonnegative",
            fixedrange: true,
            title: {
              text: "Time",
              font: {
                family:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
                size: 12,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              },
            },
            rangeselector: {
              buttons: [
                {
                  count: 1,
                  label: "1d",
                  step: "day",
                  stepmode: "backward",
                },
              ],
            },
            rangeslider: {
              visible: true,
              // thickness: 0.1,
              // borderwidth: 1,
              // bgcolor: currentTheme === "dark" ? "#424242" : "#F4F4F4",
              // range: [0],
            },
          },
          yaxis: {
            gridcolor: "gray",
            zeroline: false,
            // zerolinecolor:currentTheme === "dark" ? "#FFFFFF" : "#000000",
            line: { color: currentTheme === "dark" ? "#FFFFFF" : "#000000" },
            fixedrange: true,
            tickfont: {
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            },
            linecolor: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            title: {
              text: "Cpu Usage (%)",
              font: {
                family:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
                size: 12,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              },
            },
          },
          plot_bgcolor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
          paper_bgcolor: currentTheme === "dark" ? "#292E33" : "white",
          clickmode: "event+select",
        }}
        onClick={handleSelected}
        config={{ displayModeBar: false }}
      />
    </>
  );
}
