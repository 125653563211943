import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid } from "@material-ui/core";
import FormEditDialog from "./Dialog";
import FormAddDialog from "./DialogAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../../../axios/index";
import "./Admin.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField, InputAdornment } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../index";
import AuthContext from "../../../../hooks/useAuth";

function App() {
  const [users, SetUsers] = useState([]);
  const [openDel, setOpenDel] = React.useState(false);
  const auth = useContext(AuthContext);
  // const onChanges = (e) => {
  //   const enteredEmail = e.target.value;
  //   setIsValidEmail(enteredEmail.includes('@'));
  //   // ... rest of your onChange logic
  // };
  var randomNum = Math.floor(Math.random() * (99 - 10 + 1)) + 10;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const initialValue = {
    org_id: randomNum,
    name: "",
    email: "",
    password: null,
    phone_number: "1234509876",
    role: "",
    access_end_date: "",
    access_start_date: "",
  };
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [formData, setFormData] = useState(initialValue);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    setFormData(initialValue);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setFormData(initialValue);
  };

  const handleClickDelOpen = () => {
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const rowData = users
    ?.filter(data => data?.role !== "super_admin")
    .map(data => ({
      id: data?.id,
      name: data?.name,
      email: data?.email,
      role: data?.role,
      user_since: data?.user_since,
      access_end_date: data?.access_end_date,
      status: data?.status,
      phone_number: data?.phone_number,
      password: data?.password,
      number_of_days_left: data?.number_of_days_left,
      access_start_date: data?.access_start_date,
    }));


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1.5,
      maxWidth: 250,
      unSortIcon: true,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Date Joined",
      field: "access_start_date",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
    },
    {
      headerName: "Days Left",
      field: "number_of_days_left",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
    },
    // { headerName: "PhoneNumber", field: "phone_number" },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <EditIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => {
              handleUpdate(params.data, params.value);
            }}
          ></EditIcon>
          <DeleteIcon
            variant="outlined"
            color="secondary"
            style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
            onClick={(e) => {
              setUpdateId(params.value);
              handleClickDelOpen();
            }}
          ></DeleteIcon>
        </div>
      ),
    },
  ];

  const filteredData = rowData
    .filter((data) =>
      data.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((data) => data.number_of_days_left > 0)
    .sort((a, b) => a.name.localeCompare(b.name));

  const paginationNumberFormatter = useCallback((params) => {
    // return '[' + params.value.toLocaleString() + ']';
    return params.value;
  }, []);
  //fetching user data from server
  const getUsers = () => {
    axios.get("/users",   {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    }).then((res) => {
      SetUsers(res.data.data);
    });
  };

  const onEmail = (e) => {
    const { value, id } = e.target;
    if (value.includes("@") && value.includes(".")) {
      setFormData({ ...formData, [id]: value });
    }
  };

  const onChange = (e) => {
    const { value, id } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const onRoleChange = (e) => {
    setFormData({ ...formData, role: e.target.value });
  };
  const onDateChange = (e) => {
    setFormData({ ...formData, access_end_date: e.target.value });
  };

  //setting update row data to form data and opening pop up window
  const handleUpdate = (oldData, id) => {
    setUpdateId(id);
    delete oldData.id;
    delete oldData.number_of_days_left;
    delete oldData.status;
    delete oldData.user_since;
    setFormData(oldData);
    handleEditOpen();
  };

  //deleting a user
  const handleDelete = () => {
    handleDelClose();
    axios
      .delete("/user", {
        params: {
          id: updateId,
        },headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast.error("Deleted Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        getUsers();
      });
  };

  const sendMailtoCreatePassword = () => {
    const token = auth.token;
    if (!token) {
      toast.error("Authentication token is missing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log(formData.email, "EMAIL DATA")
    axios
      .get(
        `/createpasswordmail?email=${formData.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },

        }
      )
      .then((res) => {
        console.log(res, "RES")
        // toast.success("Link has been sent to your email!", {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // });
        // handleClose();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.warning("Password is already set, Use your Old Credentials!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        else if (error.response && error.response.status === 404) {
          toast.error("Create Password Email - Failed ", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        // handleClose();
      });
  };

  const handleFormAddSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .post("/registerUser", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleAddClose();
        sendMailtoCreatePassword();
        getUsers();
        toast.success("User Added Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Enter all credentails !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const handleFormEditSubmit = () => {
    const formDataWithOrg = { ...formData };
    const values = JSON.stringify(formDataWithOrg);
    axios
      .put("/user", values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        handleEditClose();
        getUsers();
        toast.success("User Edited Successfully !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch(() => {
        toast.error("Elements are Missing !", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <>
      <div
        style={{
          width: "81vw",
          // marginTop: "2%",
          // position: "fixed",
          // background: "skyblue",
          // height: "70vh",
        }}
      >
        <main>
          <div
            className="ag-theme-alpine"
            style={{
              height: "80vh",
              marginTop: "-3%",
              backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
            }}
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "0 12px",
                paddingTop: "40px",
              }}
            >
              <div>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder={"Search user"}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            color:
                              currentTheme === "dark" ? "#99999F" : "#818E94",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    height: "40px",
                    // width: "100%",
                    // marginLeft: '9px',
                    "& input::placeholder": {
                      textOverflow: "ellipsis !important",
                      color: currentTheme === "dark" ? "#99999F" : "#818E94",
                      opacity: 1,
                    },
                    "& .MuiInputBase-root": {
                      height: "40px !important",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "white",
                      borderRadius: "8px",
                      color: currentTheme === "dark" ? "#99999F" : "#000000",
                    },
                  }}
                />
                {/* <Typography
                  style={{
                    marginLeft: "2%",
                    fontSize: "14px",
                    marginTop: "1vh",
                    color: currentTheme === "dark" ? '#729CFF' : "#4779EF",
                    fontWeight:'600',
                  }}
                >
                  Export  <span style={{marginLeft:'7%'}}>Print</span> 
                </Typography> */}
              </div>

              <List onClick={handleClickOpen}>
                <div
                  style={{
                    color: "#FFFFFF",
                    background: "#4779EF",
                    borderRadius: "7px",
                    width: "145px",
                    marginTop: "-8px",
                    height: "40px",
                  }}
                >
                  <ListItem button>
                    <div
                      style={{
                        marginTop: "-4px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              color: "white",
                              // marginBottom: "13px",
                            }}
                          >
                            Create User
                          </Typography>
                        }
                      ></ListItemText>
                      <AddIcon style={{ marginTop: "3px" }} />
                    </div>
                  </ListItem>
                </div>
              </List>
            </Grid>
            <br></br>
            <div
              style={{
                border:
                  currentTheme === "dark"
                    ? "1px solid #646667"
                    : "1px solid #DFDFDF",
              }}
              className={
                currentTheme === "dark"
                  ? "ag-theme-alpine-dark"
                  : "ag-theme-alpine"
              }
              id="table-grid"
            >
              {/* <div style={gridStyle}> */}
              <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={filteredData}
                pagination={true}
                paginationPageSize={8}
                paginationNumberFormatter={paginationNumberFormatter}
              />
              {/* </div> */}
            </div>
          </div>

          <FormEditDialog
            open={openEdit}
            handleClose={handleEditClose}
            data={formData}
            onChange={onChange}
            onEmail={onEmail}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            updateId={updateId}
            handleFormSubmit={handleFormEditSubmit}
          />
          <FormAddDialog
            open={openAdd}
            handleClose={handleAddClose}
            data={formData}
            onChange={onChange}
            onRoleChange={onRoleChange}
            onDateChange={onDateChange}
            onEmail={onEmail}
            updateId={updateId}
            handleFormSubmit={handleFormAddSubmit}
          />

          <Dialog
            PaperProps={{
              sx: {
                width: "425px",
                height: "225px",
                backgroundColor:
                  currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
              },
            }}
            open={openDel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <DialogTitle
                style={{
                  fontSize: "20px",
                  fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              // id="alert-dialog-title"
              >
                Delete User
              </DialogTitle>
              <CancelIcon
                onClick={handleDelClose}
                style={{
                  marginTop: "4.3%",
                  cursor: "pointer",
                  marginRight: "2.5vh",
                  color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                }}
              />
            </div>
            <Divider
              style={{
                margin: "0vw 1.5vw",
                width: "80%",
                backgroundColor: "grey",
              }}
            />
            <DialogContent>
              <h4 style={{ color: "grey" }}>
                Are you sure, you want to delete the user ?
              </h4>
            </DialogContent>
            <DialogActions
              style={{
                marginLeft: "1vw",
                display: "flex",
                justifyContent: "row",
                marginRight: "5vw",
                marginTop: "125px",
                // marginBottom:'1vh',
                position: "fixed",
                gap: "20px",
              }}
            >
              <List>
                <div
                  style={{
                    color: "#FFFFFF",
                    marginTop: "8%",
                    background: "#4779EF",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "3px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDelete}
                    style={{
                      paddingLeft: "30%",
                      // marginTop: "4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Proceed
                  </ListItem>
                </div>
              </List>
              <List onClick={handleDelClose}>
                <div
                  style={{
                    color: "#4779EF",
                    marginTop: "8%",
                    border: "2px solid #4779EF",
                    boxShadow: "white",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "7px",
                    fontSize: "15px",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDelClose}
                    style={{
                      paddingLeft: "30%",
                      marginTop: "-4%",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </ListItem>
                </div>
              </List>
            </DialogActions>
          </Dialog>
          <ToastContainer
            toastStyle={{
              marginTop: "4rem",
              borderRadius: "10px",
              backgroundColor: "white",
              alignContent: "center",
              height: 50,
              margin: 10,
              width: 300,
              closeOnClick: true,
              textAlign: "center",
            }}
            closeButton={false}
            hideProgressBar={false}
            autoClose={3000}
            position="top-center"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </main>
      </div>
    </>
  );
}

export default App;
