import React, { useContext, useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AndroidIcon from "@mui/icons-material/Android";
import { Typography } from "@mui/material";
import "./filter.css";
import "./Dashboard.css";
import { StylesProvider } from "@material-ui/core/styles";
import {format, parseISO, isValid } from 'date-fns';
import { ThemePreferenceContext } from "../../../../index";
import SessionMain from "../Sessions/Sessions";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppleIcon from "@mui/icons-material/Apple";

var mapObj = {
  com: " ",
  ".oneplus": " ",
  ".qualcomm": " ",
  ".android": " ",
  ".display": " ",
  ".google": " ",
  ".tools": " ",
  ".internal": " ",
  ".emulation": " ",
  ".network": " ",
  ".dragonfistztamilan": " ",
};

export default function Dashboard() {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const role = auth.role;
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );
  const uid = auth.id || currentUserId;
  const [sessionNoteTitle, setsessionNoteTitle] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    "CPU Usage",
    "GPU Usage",
    "Avg Memory",
    "FPS",
    "Downloaded Data",
    "Uploaded Data",
    "AppPowerData",
  ];
  const handleSelectOption = (event) => {
    if (
      event &&
      typeof event === "object" &&
      "target" in event &&
      "value" in event.target
    ) {
      console.log("Selected Option Value:", event.target.value);
      setSelectedOption(event.target.value);
    } else {
      console.log("Selected Option Event:", event);
      setSelectedOption(event);
    }
  };
  const [Firstsessiondata, SetFirstsessiondata] = React.useState([]);
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const [selecteditem, setSelecteditem] = React.useState("yellow");
  const [ApplicationActive, setApplicationActive] = useState(false);
  const [DevicesActive, setDevicesActive] = useState(false);
  const [SessionsActive, setSessionsActive] = useState(false);
  const [DateActive, setDateActive] = useState(false);
  const [User, setUser] = useState("");
  const [application, setApplication] = useState("");
  const [device, setDevices] = useState("");
  const [sessions, setSessions] = useState("");
  const [userName, setUserName] = useState("");
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState("");
  const [FirstUserdata, SetFirstUserdata] = React.useState([]);
  const [Firstdevicedata, SetFirstdevicedata] = React.useState([]);
  const [Seconddevicedata, SetSeconddevicedata] = React.useState([]);
  const [selectedDeviceitem, setSelectedDeviceitem] = React.useState("yellow");
  const [selectedDevice, setSelectedDevice] = React.useState("");
  const [selectedsessionitem, setSelectedApplicationitem] = React.useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [AllSessionsActive, setAllSessionsActive] = useState(false);
  const [Allsessions, setAllsessions] = useState("");
  const [allSession, setAllSession] = useState([]);
  const [bookmarksessions, setBookmarksessions] = useState([]);
  const [pageScroll, setPageScroll] = useState(1);
  const sortedSessions = allSession?.sort(
    (a, b) => new Date(b.session_date) - new Date(a.session_date)
  );
  const sortedBookmarkSessions = bookmarksessions?.sort(
    (a, b) => new Date(b.session_date) - new Date(a.session_date)
  );
  const [calls, setcall] = useState(uid);
  const [currentSessionType, setCurrentSessionType] = useState("recent");

  React.useEffect(() => {
    let isMount = true;
    axios
      .get("/users", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (isMount) {
          SetFirstUserdata(res.data.data);
        }
      })
      .catch((err) => {});
    return () => {
      isMount = false;
    };
  }, []);

  const dropdownRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDevicesActive(false);
      setApplicationActive(false);
      setSessionsActive(false);
      setDateActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  React.useEffect(() => {
    let isMount = true;
    if (uid && startDate && endDate) {
      axios
        .get("/devices", {
          params: {
            userId: uid,
            fromDate: startDate,
            toDate: endDate,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            SetFirstdevicedata(res.data.data);
            global.device_name = res.data.device_name;
            SetSeconddevicedata(null);
            SetFirstsessiondata(null);
          } else {
            SetFirstdevicedata(null);
          }
        })
        .catch((err) => {
          SetFirstdevicedata(null);
          SetSeconddevicedata(null);
          SetFirstsessiondata(null);
        });
    }
    return () => {
      isMount = false;
    };
  }, [startDate, endDate]);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return "";
    }
    return format(date, "dd-MMM-yyyy");
  }

  //Display available Application
  function singleDeviceItem(e, data, id) {
    setSelectedDeviceitem(id);
    axios
      .get("/applications", {
        params: {
          deviceId: data.device_id,
          userId: uid,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSeconddevicedata(res.data.data);
      });
  }

  //All Session function
  function singleApplicationItem(e, data, id) {
    setSelectedApplicationitem(id);
    axios
      .get("/allSessions", {
        params: {
          deviceId: data.device_id,
          userId: uid,
          appName: data.app_name,
          fromDate: startDate,
          toDate: endDate,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetFirstsessiondata(res.data.data);
      })
      .catch((err) => {
        toast.error("Oops! No Sessions!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  }

  useEffect(() => {
    Sessions(pageScroll);
    MyBookmarkSessions();
  }, [calls]);

  function MyBookmarkSessions() {
    axios
      .get("/myBookmarks", {
        params: {
          user_id: calls,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setBookmarksessions([]);
        } else {
          setBookmarksessions(res.data.data);
          singleSessionItem();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setBookmarksessions([]);
        }
      });
  }

  function Sessions(pageNum) {
    axios
      .get("/mySessions", {
        params: {
          userId: calls,
          page: pageNum,
          size: 50,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        if (res.status === 204) {
          setAllSession([]);
        } else {
          if (res?.data?.data?.length) {
            setAllSession((prevRes) => [...prevRes, ...res.data.data]);
          }
          singleSessionItem();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log(error);
        }
      });
  }

  const handleSessionTypeChange = (type) => {
    setCurrentSessionType(type);
  };

  //Display specfic session details
  function singleSessionItem(e, data, id) {
    setSelecteditem(id);
    axios
      .get("/sessionDetails", {
        params: {
          DeviceId: data.device_id,
          appName: data.app_name,
          userId: uid,
          sessionId: data.session_id,
          createdDate: data.session_date,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSecondsessiondata(res.data.data[0]);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.createdDate = data.session_date;
        setTimeout(() => {
          setSelectedDevice(data.sessionname);
        }, 100);
      });
  }

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      setPageScroll((prevRes) => prevRes + 1);
    }
  };

  useEffect(() => {
    if (pageScroll > 1) {
      Sessions(pageScroll);
    }
  }, [pageScroll]);

  React.useEffect(() => {
    const sessionData = window.sessionStorage.getItem("sessiondata");
    const savedValues = JSON.parse(sessionData);
    SetFirstdevicedata(savedValues?.Firstdevicedata);
    setsessionNoteTitle(savedValues?.sessionNoteTitle);
    SetSeconddevicedata(savedValues?.Seconddevicedata);
    SetFirstsessiondata(savedValues?.Firstsessiondata);
    SetSecondsessiondata(savedValues?.Secondsessiondata);
    setNotes(savedValues?.notes);
    setStartDate(savedValues?.startDate);
    setEndDate(savedValues?.endDate);
    setDevices(savedValues?.device);
    setApplication(savedValues?.application);
    setSessions(savedValues?.sessions);
    setUserName(savedValues?.user_name);
    setUser(savedValues?.User);
  }, []);

  React.useEffect(() => {
    const valuesToSave = {
      Firstdevicedata,
      sessionNoteTitle,
      Seconddevicedata,
      Firstsessiondata,
      Secondsessiondata,
      date,
      device,
      notes,
      application,
      sessions,
      startDate,
      endDate,
      User,
      userName,
    };
    const storage = localStorage.getItem("data");
    const data = JSON.parse(storage);
    // window.sessionStorage.setItem("sessiondata", JSON.stringify(valuesToSave));
  });

  const remainingPercentage = 56;
  const usedPercentage = 30;
  const sortedSessList =
    currentSessionType === "recent" ? sortedSessions : sortedBookmarkSessions;

  return (<>
    <div
      style={{
        width: "82vw",
        position: "fixed",
        height: "85vh",
      }}
    >
      <main>
        <div style={{ margin: 10 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gap: "1%",
              width: "100%",
              height: "100%",
              marginTop: "1rem",
            }}
          >
            <div style={{ gridRow: "1 / span 1" }}>
              <p
                className="headertitle"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  paddingBottom: "3px",
                  marginBottom: "5px",
                }}
              >
                Quick Stats
              </p>
              <div
                style={{
                  width: "24vw",
                  height: "25vh",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListItemText>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "15px",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <Typography
                      variant="body"
                      style={{
                        fontWeight: "600",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                      }}
                      className="fonts"
                    >
                      My Sessions
                      <span
                        style={{
                          fontWeight: "600",
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                          top: "20px",
                          right: "65px",
                          position: "relative",
                        }}
                        className="number"
                      >
                        {sortedSessions?.length > 0
                          ? sortedSessions[0]?.total_sessions
                          : "-"}
                      </span>
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        fontWeight: "600",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                      }}
                      className="fonts"
                    >
                      Tested Devices
                      <span
                        style={{
                          fontWeight: "600",
                          marginLeft: "10px",
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                          top: "20px",
                          right: "92px",
                          position: "relative",
                        }}
                        className="number"
                      >
                        {sortedSessions?.length > 0
                          ? sortedSessions[0]?.total_device_count
                          : "-"}
                      </span>
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        fontWeight: "600",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                      }}
                      className="fonts"
                    >
                      Tested Apps
                      <span
                        style={{
                          fontWeight: "600",
                          marginLeft: "3px",
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                          top: "20px",
                          right: "72px",
                          position: "relative",
                        }}
                        className="number"
                      >
                        {sortedSessions?.length > 0
                          ? sortedSessions[0]?.total_app_count
                          : "-"}
                      </span>
                    </Typography>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      marginTop: "8vh",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body"
                      style={{
                        fontWeight: "600",
                        marginLeft: "15px",
                        marginTop: "10px",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                      }}
                      className="fonts"
                    >
                      My Allocated Hours
                      <span
                        style={{
                          fontWeight: "600",
                          marginLeft: "15px",
                          top: "20px",
                          right: "123px",
                          position: "relative",
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                        }}
                        className="number1"
                      >
                        86
                      </span>
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "20%",
                        marginRight: "30%",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                        fontWeight: "600",
                      }}
                      className="proglabel"
                    >
                      <div style={{ marginRight: "10px", marginTop: "10px" }}>
                        <span>Remaining</span>
                        <div style={{ width: "3vw" }}>
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: "#4779EF",
                              trailColor: "lightgrey",
                              textColor:
                                currentTheme === "dark" ? "white" : "black",
                              textSize: "25px",
                            })}
                            value={remainingPercentage}
                            text={`${remainingPercentage}`}
                          />
                        </div>
                      </div>
                      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
                        <span style={{ marginLeft: "0.6vw" }}>Used</span>
                        <div style={{ width: "3vw" }}>
                          <CircularProgressbar
                            styles={buildStyles({
                              pathColor: "#FEC400",
                              trailColor: "lightgrey",
                              textColor:
                                currentTheme === "dark" ? "white" : "black",
                              textSize: "25px",
                            })}
                            value={usedPercentage}
                            text={`${usedPercentage}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </ListItemText>
              </div>
            </div>
            <div style={{ gridRow: "1 / span 1" }}>
              <p
                className="headertitle"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  paddingBottom: "3px",
                  marginBottom: "5px",
                }}
              >
                Session Hours Summary
              </p>
              <div
                style={{
                  width: "24vw",
                  height: "25vh",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListItemText>
                  <Typography
                    variant="body"
                    style={{
                      fontWeight: "600",
                      marginLeft: "15px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "black",
                    }}
                    className="fonts"
                  >
                    Current Plan
                    <span
                      style={{
                        fontWeight: "400",
                        marginLeft: "15px",
                        color:
                          currentTheme === "dark" ? "#99999F" : "#818E94",
                        top: "20px",
                        right: "83px",
                        position: "relative",
                      }}
                      className="number"
                    >
                      Prepaid Hours
                    </span>
                  </Typography>
                  <hr
                    style={{
                      border: "none",
                      borderBottom:
                        currentTheme === "dark"
                          ? "0.5px solid #A2B1C2"
                          : "1px solid #e6e6e6",
                      marginTop: "8vh",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body"
                      style={{
                        fontWeight: "600",
                        marginLeft: "15px",
                        marginTop: "10px",
                        color: currentTheme === "dark" ? "#FFFFFF" : "black",
                      }}
                      className="fonts"
                    >
                      Hours Spent
                      <span
                        style={{
                          fontWeight: "400",
                          marginLeft: "15px",
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                          top: "20px",
                          right: "82px",
                          position: "relative",
                        }}
                        className="number1"
                      >
                        30:00/60.00
                      </span>
                    </Typography>
                  </div>
                  <button className="request-button">Request Hours</button>
                </ListItemText>
              </div>
            </div>
            <div
              style={{
                gridRow: "1 / span 2",
                gridColumn: "3 / span 1",
              }}
            >
              <div>
                <span
                  onClick={() => handleSessionTypeChange("recent")}
                  style={{
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                >
                  <span
                    style={{
                      borderBottom:
                        currentSessionType === "recent"
                          ? "2px solid #6492FF"
                          : "none",
                      color: currentTheme === "dark" ? "white" : "black",
                      paddingBottom: "1px",
                    }}
                    className="headertitle"
                  >
                    My Recent Sessions
                  </span>
                </span>

                <span
                  onClick={() => handleSessionTypeChange("bookmarked")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      borderBottom:
                        currentSessionType === "bookmarked"
                          ? "2px solid #6492FF"
                          : "none",
                      color: currentTheme === "dark" ? "white" : "black",
                      paddingBottom: "1px",
                    }}
                    className="headertitle"
                  >
                    My Bookmarked Sessions
                  </span>
                </span>
                <div
                  value={currentTheme}
                  onChange={(e) => {
                    setCurrentTheme(e.target.value);
                  }}
                  style={{
                    display: AllSessionsActive != 404 ? "block" : "none",
                    width: "30vw",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "10px",
                    height: "73vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    scrollBehavior: "smooth",
                    boxShadow: "0px 3px 6px #0000000A",
                    marginTop: "1.5vh",
                  }}
                  className="ScrollBars"
                  onScroll={(e) => handleScroll(e)}
                >
                  {sortedSessList?.length > 0 ? (
                    sortedSessList.map((data, i) => (
                      <div
                        key={i}
                        onClick={(e) => {
                          setAllsessions(e.target.textContent);
                          setAllSessionsActive(!AllSessionsActive);
                          singleSessionItem(e, data, i);
                        }}
                        style={{
                          color: currentTheme === "dark" ? "white" : "black",
                          fontSize: "20px",
                          borderRadius: "5px",
                          margin: "5px",
                          width: "95%",
                        }}
                      >
                        <ListItem>
                          {data.device_name === "apple" ? (
                            <AppleIcon
                              sx={{ fontSize: 40, marginLeft: "-15px" }}
                            />
                          ) : (
                            <AndroidIcon
                              sx={{ fontSize: 40, marginLeft: "-15px" }}
                            />
                          )}
                          <ListItemText>
                            <Typography
                              variant="body"
                              style={{
                                fontWeight: "600",
                                marginLeft: "15px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                textTransform: "capitalize",
                              }}
                              title={data?.sessionname}
                              className="app-name"
                            >
                              {data?.sessionname}
                            </Typography>
                            <br />
                            <div
                              style={{
                                marginLeft: "15px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                marginBottom: "-25px",
                              }}
                              title={data?.app_name}
                              className="app-name"
                            >
                              {data?.app_name}
                            </div>
                            <br />
                            <Typography
                              style={{
                                marginLeft: "15px",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif`,
                                marginTop: "2px",
                                color:
                                  currentTheme === "dark"
                                    ? "#A2B1C2"
                                    : "black",
                              }}
                              id="scroll-fonts"
                            >
                              Created Date:{" "}
                              <span
                                style={{
                                  color: "#4779EF",
                                  fontWeight: "600",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                title={data?.session_date}
                                id="scroll-fonts"
                              >
                                {data?.session_date}
                              </span>
                            </Typography>
                            <Typography
                              style={{
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif`,
                                color:
                                  currentTheme === "dark"
                                    ? "#A2B1C2"
                                    : "black",
                              }}
                              id="scroll-fonts1"
                            >
                              Tested Device:{" "}
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  color: "#4779EF",
                                  fontWeight: "600",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                title={data.device_name}
                                id="scroll-fonts"
                              >
                                {data.device_name}
                              </span>
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        {i < sortedSessions.length - 1 && (
                          <hr
                            style={{
                              border: "none",
                              borderBottom:
                                currentTheme === "dark"
                                  ? "0.5px solid #A2B1C2"
                                  : "1px solid #e6e6e6",
                              margin: "5px 0",
                            }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: currentTheme === "dark" ? "white" : "black",
                      }}
                    >
                      {AllSessionsActive == 404
                        ? "No Recent sessions"
                        : "No Recent logs!"}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                gridRow: "2 / span 1",
                gridColumn: "1 / span 2",
              }}
            >
              <p
                className="headertitle"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  marginTop: "-8.5vh",
                  paddingBottom: "8px",
                }}
              >
                Performance Trends
              </p>
              <div
                style={{
                  height: "43vh",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                }}
                className="performance-trends"
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {/* Date Dropdown */}
                  <div className="dropdown-containers">
<div
  onClick={(e) => {
    e.stopPropagation();
    handleOutsideClick(DateActive);
    setDateActive(!DateActive);
    handleSelectOption(null);
    setSelectedOption("Select Metrics");
  }}
  className="dropdown-buttons"
  style={{
    backgroundColor:
      currentTheme === "dark" ? "#292E33" : "white",
    color: currentTheme === "dark" ? "white" : "black",
  }}
  ref={dropdownRef}
>
  {startDate ? (
    <p style={{ fontSize: "10px" }}>
      {formatDate(startDate)} ↔{" "}
      {endDate ? formatDate(endDate) : ""}
    </p>
  ) : (
    <p>{date ? date : "Date"}</p>
  )}
</div>

<div
  className="dropdown-contents"
  style={{ display: DateActive ? "block" : "none" }}
>
  <div
    onClick={(e) => {
      setDate(e.target.textContent);
      setDateActive(!DateActive);
    }}
  >
    <StylesProvider injectFirst>
      <br></br>
      <div className="dates">
        <TextField
          id="date"
          label="From"
          type="date"
          value={startDate}
          onChange={(e) => {
            const value = e.target.value;
            if (endDate && new Date(value) > new Date(endDate)) {
              setEndDate(value);
            }
            if (!endDate) {
              setStartDate(value);
              if (value) {
                setDate(formatDate(value) + " ↔ " + formatDate(endDate));
              } else {
                setDate(formatDate(endDate));
              }
            } else {
              setStartDate(value);
              if (value) {
                setDate(formatDate(value) + " ↔ " + formatDate(endDate));
              } else {
                setDate(formatDate(endDate));
              }
            }
            setDevices("");
            setApplication("");
            setSessions("");
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            max: endDate,
            style: {
              padding: 1.5,
              width: "120px",
            },
          }}
        />
        <br></br>
        <br></br>
        <TextField
          id="date"
          label="To"
          type="date"
          value={endDate}
          onChange={(e) => {
            const value = e.target.value;
            setEndDate(value);
            if (startDate && new Date(value) < new Date(startDate)) {
              setStartDate(value);
            }
            if (!value && !startDate) {
              setDate("Date");
            } else if (!value) {
              setDate(formatDate(startDate));
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: startDate,
            style: {
              padding: 1.5,
              width: "120px",
            },
          }}
        />
      </div>
    </StylesProvider>
  </div>
</div>
</div>


                  {/* Device Dropdown */}
                  <div className="dropdown-container">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOutsideClick(DevicesActive);
                        setDevicesActive(!DevicesActive);
                        handleSelectOption(null);
                        setSelectedOption("Select Metrics");
                      }}
                      className="dropdown-button"
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        textTransform: "capitalize",
                      }}
                      ref={dropdownRef}
                    >
                      <div>
                        {device ? device : "Device"}
                        <span className="down-arrow">&#9660;</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: DevicesActive ? "block" : "none",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                      }}
                      className="dropdown-content"
                    >
                      <div
                        className="scrollable-list"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                        }}
                      >
                        {Firstdevicedata &&
                          Firstdevicedata.map((data, i) => (
                            <div
                              onClick={(e) => {
                                setDevices(e.target.textContent);
                                setDevicesActive(!DevicesActive);
                                singleDeviceItem(e, data, i);
                              }}
                              style={{
                                backgroundColor:
                                  currentTheme === "dark"
                                    ? "#292E33"
                                    : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {data.device_name}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {/* Application Dropdown */}
                  <div className="dropdown-container">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOutsideClick(ApplicationActive);
                        setApplicationActive(!ApplicationActive);
                        handleSelectOption(null);
                        setSelectedOption("Select Metrics");
                      }}
                      className="dropdown-button"
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                      }}
                      ref={dropdownRef}
                    >
                      <div>
                        {application ? application : "Application"}
                        <span className="down-arrow">&#9660;</span>{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        display: ApplicationActive ? "block" : "none",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                      }}
                      className="dropdown-content"
                    >
                      <div
                        className="scrollable-list"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                        }}
                      >
                        {Seconddevicedata?.map((data, i) => (
                          <div
                            onClick={(e) => {
                              setApplication(e.target.textContent);
                              setApplicationActive(!ApplicationActive);
                              singleApplicationItem(e, data, i);
                            }}
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color:
                                currentTheme === "dark" ? "white" : "black",
                            }}
                          >
                            {data.app_name.replace(
                              /com|.qualcomm|.oneplus|.android|.display|.google|.tools|.internal|.emulation|.dragonfistztamilan|.network/gi,
                              function (matched) {
                                return mapObj[matched];
                              }
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Sessions Dropdown */}
                  <div className="dropdown-container">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOutsideClick(SessionsActive);
                        setSessionsActive(!SessionsActive);
                        handleSelectOption(null);
                        setSelectedOption("Select Metrics");
                      }}
                      className="dropdown-button"
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        color: currentTheme === "dark" ? "white" : "black",
                        textTransform: "capitalize",
                      }}
                      ref={dropdownRef}
                    >
                      <div>
                        {sessions ? sessions : "Sessions"}
                        <span className="down-arrow">&#9660;</span>{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        display: SessionsActive ? "block" : "none",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                      }}
                      className="dropdown-content"
                    >
                      <div
                        className="scrollable-list"
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "white",
                          color: currentTheme === "dark" ? "white" : "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {Firstsessiondata?.map((data, i) => (
                          <div
                            key={i}
                            onClick={(e) => {
                              setSessions(e.target.textContent);
                              setSessionsActive(!SessionsActive);
                              singleSessionItem(e, data, i);
                            }}
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "white",
                              color:
                                currentTheme === "dark" ? "white" : "black",
                            }}
                          >
                            {data.sessionname != ""
                              ? data.sessionname
                              : "no session name"}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      zIndex: "1500",
                      marginTop: "10px",
                      pointerEvents: startDate ? "auto" : "none",
                    }}
                  >
                    <select
                      className="custom-metricbutton"
                      value={selectedOption}
                      onChange={handleSelectOption}
                    >
                      <option value="">Select Metrics</option>
                      {options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <SessionMain
                    User_id={uid}
                    currentTheme={currentTheme}
                    selectedOption={selectedOption}
                  />
                  <ToastContainer
                    toastStyle={{
                      marginTop: "4rem",
                      borderRadius: "10px",
                      backgroundColor: "white",
                      alignContent: "center",
                      height: 50,
                      margin: 10,
                      width: 300,
                      closeOnClick: true,
                      textAlign: "center",
                    }}
                    closeButton={false}
                    hideProgressBar={false}
                    autoClose={2000}
                    position="top-center"
                    className="toast-container"
                    toastClassName="dark-toast"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </>);
}
