import React, { useContext, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import TextField from "@mui/material/TextField";
import { TextField, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider } from "@mui/material";
import "./SuperAdmin.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CheckBox } from "@mui/icons-material";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";

export default function FormAddDialog({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onRoleChange,
  onChangePhone,
  onDateChange,
  formData,
  updateId,
  onChange3,
  onChange2,
}) {
  const {
    org_id,
    org_name,
    admin_email_id,
    pc_first_name,
    pc_last_name,
    contact_address_line_1,
    contact_address_line_2,
    contact_city,
    contact_state_province,
    contact_zip_code,
    contact_country,
    phone_country_code,
    phone_number,
    created_at,
    subscription_type,
    status,
    bc_first_name,
    bc_last_name,
    billing_address_line_1,
    billing_address_line_2,
    billing_city,
    billing_state_province,
    billing_zip_code,
    billing_country,
    tax_id,
  } = data;

  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedName, setIsCheckedName] = useState(false);
  const [date, setDate] = useState(new Date());

  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [countries, SetCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryBill, setSelectedCountryBill] = useState("");

  const [validationErrors, setValidationErrors] = useState({
    org_name: false,
    admin_email_id: false,
    pc_first_name: false,
    pc_last_name: false,
    contact_address_line_1: false,
    // contact_address_line_2: false,
    contact_city: false,
    contact_state_province: false,
    contact_zip_code: false,
    contact_country: false,
    phone_country_code: false,
    phone_number: false,
    created_at: false,
    subscription_type: false,
    status: false,
    bc_first_name: false,
    bc_last_name: false,
    billing_address_line_1: false,
    // billing_address_line_2: false,
    billing_city: false,
    billing_state_province: false,
    billing_zip_code: false,
    billing_country: false,
  });

  const auth = useContext(AuthContext);
  useEffect(() => {
    axios
      .get("/countrydetails", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // Sort countries alphabetically
        const sortedCountries = res.data.data.sort((a, b) =>
          a.country_name.localeCompare(b.country_name)
        );
        SetCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate org_name
    if (data.org_name.trim() === "") {
      errors.org_name = true;
      isValid = false;
    } else {
      errors.org_name = false;
    }

    // Validate admin_email_id
    if (data.admin_email_id.trim() === "") {
      errors.admin_email_id = true;
      isValid = false;
    } else {
      errors.admin_email_id = false;
    }

    // Validate id
    // if (data.tax_id.trim() === "") {
    //   errors.tax_id = true;
    //   isValid = false;
    // } else {
    //   errors.tax_id = false;
    // }

    // Validate pc_first_name
    if (data.pc_first_name.trim() === "") {
      errors.pc_first_name = true;
      isValid = false;
    } else {
      errors.pc_first_name = false;
    }

    // Validate pc_last_name
    if (data.pc_last_name.trim() === "") {
      errors.pc_last_name = true;
      isValid = false;
    } else {
      errors.pc_last_name = false;
    }

    // Validate contact_address_line_1
    if (data.contact_address_line_1.trim() === "") {
      errors.contact_address_line_1 = true;
      isValid = false;
    } else {
      errors.contact_address_line_1 = false;
    }

    // Validate contact_address_line_2
    // Assuming contact_address_line_2 is optional, no validation needed

    // Validate contact_city
    if (data.contact_city.trim() === "") {
      errors.contact_city = true;
      isValid = false;
    } else {
      errors.contact_city = false;
    }

    // Validate contact_state_province
    if (data.contact_state_province.trim() === "") {
      errors.contact_state_province = true;
      isValid = false;
    } else {
      errors.contact_state_province = false;
    }

    // Validate contact_zip_code
    if (data.contact_zip_code.trim() === "") {
      errors.contact_zip_code = true;
      isValid = false;
    } else {
      errors.contact_zip_code = false;
    }

    // Validate contact_country
    if (data.contact_country.trim() === "") {
      errors.contact_country = true;
      isValid = false;
    } else {
      errors.contact_country = false;
    }

    // Validate phone_country_code
    if (data.phone_country_code.trim() === "") {
      errors.phone_country_code = true;
      isValid = false;
    } else {
      errors.phone_country_code = false;
    }

    // Validate phone_number
    if (data.phone_number.trim() === "") {
      errors.phone_number = true;
      isValid = false;
    } else {
      errors.phone_number = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const validateForm1 = () => {
    let errors = {};
    let isValid = true;
    // Validate bc_first_name
    if (data.bc_first_name.trim() === "") {
      errors.bc_first_name = true;
      isValid = false;
    } else {
      errors.bc_first_name = false;
    }

    // Validate bc_last_name
    if (data.bc_last_name.trim() === "") {
      errors.bc_last_name = true;
      isValid = false;
    } else {
      errors.bc_last_name = false;
    }

    // Validate billing_address_line_1
    if (data.billing_address_line_1.trim() === "") {
      errors.billing_address_line_1 = true;
      isValid = false;
    } else {
      errors.billing_address_line_1 = false;
    }

    // Validate billing_address_line_2
    // Assuming billing_address_line_2 is optional, no validation needed

    // Validate billing_city
    if (data.billing_city.trim() === "") {
      errors.billing_city = true;
      isValid = false;
    } else {
      errors.billing_city = false;
    }

    // Validate billing_state_province
    if (data.billing_state_province.trim() === "") {
      errors.billing_state_province = true;
      isValid = false;
    } else {
      errors.billing_state_province = false;
    }

    // Validate billing_zip_code
    if (data.billing_zip_code.trim() === "") {
      errors.billing_zip_code = true;
      isValid = false;
    } else {
      errors.billing_zip_code = false;
    }

    // Validate billing_country
    if (data.billing_country.trim() === "") {
      errors.billing_country = true;
      isValid = false;
    } else {
      errors.billing_country = false;
    }

    setValidationErrors(errors);
    return isValid;
  };
  const handleChange = (event) => {
    // console.log("before");
    setSelectedCountry(event.target.value);
    // console.log("after", event.target.value);
    onChange3(event.target.value);
  };
  const handleChange2 = (event) => {
    // console.log("before bill");
    setSelectedCountryBill(event.target.value);
    // console.log("after bill");
    onChange2(event.target.value);
  };

  // Handle next button click

  const handleNext = () => {
    if (validateForm()) {
      setCurrentPage(2); // Proceed to the next page if the form is valid
    }
    else {
      toast.error("Please fill in all required fields!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // const sendMailtoCreatePassword = () => {
  //   const token = auth.token;
  //   if (!token) {
  //     toast.error("Authentication token is missing", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     return;
  //   }
  //   axios
  //     .get(
  //       `/createpasswordmail/${admin_email_id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         // params: {
  //         //   email: admin_email_id,
  //         // },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res,"RES")
  //       toast.success("Link has been sent to your email!", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("error while sending mail")
  //       toast.error("Failed to send reset link", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     });
  // };
  

  const handleCreate = () => {
    if (validateForm1()) {
      handleClose();
      handleFormSubmit();
      setCurrentPage(1);
      setIsCheckedName(false);
      setIsChecked(false);
      setSelectedCountry("");
      setSelectedCountryBill("");
      // sendMailtoCreatePassword();
    } else {
      setCurrentPage(2);
      toast.error("Please fill in all required fields!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleClose1 = () => {
    setCurrentPage(1);
  };

  const handleNameCheckBox = () => {
    setIsCheckedName(!isCheckedName);
    if (!isCheckedName) {
      // If the checkbox was previously unchecked, copy data from the first page to the second page
      onChange({
        target: {
          id: "bc_first_name",
          value: data.pc_first_name,
        },
      });
      onChange({
        target: {
          id: "bc_last_name",
          value: data.pc_last_name,
        },
      });
    } else {
      // If the checkbox was previously checked, clear the fields
      onChange({
        target: {
          id: "bc_first_name",
          value: "",
        },
      });
      onChange({
        target: {
          id: "bc_last_name",
          value: "",
        },
      });
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state

    if (!isChecked) {
      // If the checkbox was unchecked, copy data from the contact fields to billing fields
      const fieldsToCopy = [
        {
          id: "billing_address_line_1",
          value: data.contact_address_line_1,
        },
        {
          id: "billing_address_line_2",
          value: data.contact_address_line_2,
        },
        {
          id: "billing_city",
          value: data.contact_city,
        },
        {
          id: "billing_state_province",
          value: data.contact_state_province,
        },
        {
          id: "billing_zip_code",
          value: data.contact_zip_code,
        },
        {
          id: "billing_country",
          value: data.contact_country,
        },
        // Add more fields if needed
      ];

      // Copy values from contact fields to billing fields
      fieldsToCopy.forEach(field => {
        onChange({
          target: {
            id: field.id,
            value: field.value,
          },
        });
      });
    } else {
      // If the checkbox was checked, clear the billing fields
      const fieldsToClear = [
        "billing_address_line_1",
        "billing_address_line_2",
        "billing_city",
        "billing_state_province",
        "billing_zip_code",
        "billing_country",
        // Add more fields if needed
      ];

      // Clear values of billing fields
      fieldsToClear.forEach(fieldId => {
        onChange({
          target: {
            id: fieldId,
            value: "",
          },
        });
      });
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
            minWidth: "800px",
            // width: "600px",
            // width: "40vw",
            maxHeight: "700px",
            backgroundColor: currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <DialogTitle
            style={{
              fontSize: "20px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          // id="alert-dialog-title"
          >
            Enroll Organization
          </DialogTitle>
          <CancelIcon
            onClick={() => {
              setValidationErrors(false);
              handleClose();
              setCurrentPage(1);
              setIsCheckedName(false);
              setIsChecked(false);

              setSelectedCountry("");
              setSelectedCountryBill("");
            }}
            style={{
              marginTop: "2%",
              cursor: "pointer",
              marginRight: "3vh",
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          />
        </div>
        <Divider
          style={{
            margin: "0vw 1.5vw",
            maxWidth: "100%",
            backgroundColor: "grey",
          }}
        />
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <form>
            {/* First Page */}
            {currentPage === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px", // Add margin to separate from the next row
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  > */}
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Organization Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="org_name"
                      value={formData?.org_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        color: validationErrors.org_name ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.org_name}
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      First Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="pc_first_name"
                      value={formData?.pc_first_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        color: validationErrors.pc_first_name ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.pc_first_name}
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="pc_last_name"
                      value={formData?.pc_last_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        color: validationErrors.pc_last_name ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.pc_last_name}
                    />
                  </div>
                </div>
                {/* <br/> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px", // Add margin to separate from the next row
                    marginTop: "-2%",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Primary Email<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="admin_email_id"
                      type="email"
                      value={formData?.admin_email_id}
                      onChange={onEmail}
                      
                      InputProps={{
                        placeholder: "Email",
                        color: validationErrors.admin_email_id ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            // textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}

                      // label="Email"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.admin_email_id}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      marginLeft: "-240px",
                    }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      Tax ID
                    </Typography>
                    <TextField
                      id="tax_id"
                      value={formData?.tax_id}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "ID",
                        // color: validationErrors.tax_id ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    // error={validationErrors.tax_id}
                    />
                  </div>
                </div>
                {/* <br/> */}
                {/* Second Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ flex: 1, marginRight: "10px", marginTop: "1%" }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      Contact Address<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="contact_address_line_1"
                      value={formData?.contact_address_line_1}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Address Line 1",
                        color: validationErrors.contact_address_line_1
                          ? "red"
                          : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.contact_address_line_1}
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Address Line 2 (optional)
                      </Typography> */}
                    <TextField
                      id="contact_address_line_2"
                      value={formData?.contact_address_line_2}
                      onChange={onChange}
                      variant="outlined"
                      // required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Address Line 2 (optional)",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "16%",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact City<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="contact_city"
                      value={formData?.contact_city}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "City",
                        color: validationErrors.contact_city ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "16%",
                      }}
                      margin="dense"
                      error={validationErrors.contact_city}
                    />
                  </div>
                </div>
                {/* third row */}
                {/* <br/> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
      Contact State<span style={{ color: "red" }}>*</span>
    </Typography> */}
                    <TextField
                      id="contact_state_province"
                      value={formData?.contact_state_province}
                      onChange={(e) => onChange(e)}
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "State",
                        color: validationErrors.contact_state_province
                          ? "red"
                          : "",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                      error={validationErrors.contact_state_province}
                    />
                  </div>

                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact Country<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <Select
                      id="contact_country"
                      value={selectedCountry}
                      onChange={handleChange}
                      displayEmpty
                      variant="outlined"
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // adjust as needed
                            width: 200, // adjust as needed
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          },
                          className:"ScrollBars"
                        },
                      }}
                      style={{
                        color:
                        selectedCountry ? (selectedCountry === "Country" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        height: "40px",
                        width: "210px",
                        marginTop: "2%",
                        border: validationErrors.contact_country
                          ? "1px solid red"
                          : "",
                      }}
                      margin="dense"
                    >
                      <MenuItem value="" style={{ color: "#818E94",fontSize: "14px", }}>
                        {selectedCountry ? selectedCountry : "Country"}
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_id}
                          value={country.country_name}
                          style={{
                            color: "#818E94",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {country.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact Pin Code<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="contact_zip_code"
                      value={formData?.contact_zip_code}
                      onChange={(e) => onChange(e)}
                      variant="outlined"
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Pin/Zip Code",
                        color: validationErrors.contact_zip_code ? "red" : "",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                      error={validationErrors.contact_zip_code}
                    />
                  </div>
                </div>
                <Typography
                  style={{
                    color: "#818E94",
                    whiteSpace: "nowrap",
                    marginTop: "1%",
                  }}
                >
                  Contact No<span style={{ color: "red" }}>*</span>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <PhoneInput
                    id="phone_country_code"
                    country={"in"}
                    value={`${formData?.phone_country_code}${formData?.phone_number}`}
                    error={validationErrors.phone_number ? true : false} // Set error prop based on validationErrors.phone_number
                    inputProps={{
                      required: true,
                      autoFocus: true,
                      color: validationErrors.phone_number ? "red" : "",
                    }}
                    inputStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      borderRadius: "5px",
                      width: "210px",
                      height: "39px",
                      // marginTop: "-1px",
                      border: validationErrors.phone_number
                        ? "1px solid red"
                        : "1px solid",
                      borderColor: currentTheme === "dark" ? "transparent" : "",
                    }}
                    onChange={onChangePhone}
                    // disableSearchIcon={false}
                    // enableSearch={true}
                    searchPlaceholder="Search"
                    searchStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      width: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                    dropdownStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      // border: "1px solid #ccc",
                      width: "220px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                    }}
                    buttonStyle={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      border: validationErrors.phone_number
                        ? "1px solid red"
                        : "1px solid",
                      borderColor: currentTheme === "dark" ? "transparent" : "",
                    }}
                  />

                </div>
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >

                </div>

                <div style={{ flex: 1 }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          setValidationErrors(false);
                          handleClose();
                          setCurrentPage(1);
                          setIsCheckedName(false);
                          setIsChecked(false);
                          setSelectedCountry("");
                          setSelectedCountryBill("");
                        }}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Cancel
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "20px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleNext();
                        }}
                      >
                        Next
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </>
            )}
          </form>
          <form>
            {/* Second Page */}
            {currentPage === 2 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    // marginTop:"-7%"
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  > */}
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      First Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="bc_first_name"
                      value={
                        isCheckedName
                          ? data.bc_first_name
                          : isCheckedName === false
                            ? ""
                            : formData?.bc_first_name
                      }
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        color: validationErrors.bc_first_name ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.bc_first_name}
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      marginLeft: "-230px",
                    }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="bc_last_name"
                      value={
                        isCheckedName
                          ? data.bc_last_name
                          : isCheckedName === false
                            ? ""
                            : formData?.bc_last_name
                      }
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        color: validationErrors.bc_last_name
                          ? "red"
                          : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      error={validationErrors.bc_last_name}
                    />
                  </div>
                </div>
                {/* <br /> */}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isCheckedName}
                    onClick={handleNameCheckBox}
                    color="primary"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  />
                  <span style={{ marginLeft: "1%", color: "#818E94" }}>
                    Same as Primary Contact
                  </span>
                </div>
                <br />
                {/* Second Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Billing Address
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="billing_address_line_1"
                      value={data.billing_address_line_1}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Address Line 1",
                        color: validationErrors.billing_address_line_1 ? "red" : "",
                        inputProps: {
                          style: {
                            color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                          disabled: isChecked, // Address Line 1 should be disabled when checkbox is checked
                        },
                      }}
                      variant="outlined"
                      required
                      style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      disabled={!isChecked}
                      error={validationErrors.billing_address_line_1}
                    />
                  </div>
                  <div style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}>
                    <TextField
                      id="billing_address_line_2"
                      value={data.billing_address_line_2}
                      onChange={onChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Address Line 2 (optional)",
                        style: {
                          color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                        disabled: isChecked, // All other fields should be disabled when checkbox is checked
                      }}
                      style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "13%",
                      }}
                      margin="dense"
                      disabled={!isChecked} // Make it editable only when checkbox is unchecked
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <TextField
                      id="billing_city"
                      value={data.billing_city}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "City",
                        color: validationErrors.billing_city ? "red" : "",
                        inputProps: {
                          style: {
                            color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                          disabled: isChecked, // All other fields should be disabled when checkbox is checked
                        },
                      }}
                      variant="outlined"
                      required
                      style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "13%",
                      }}
                      margin="dense"
                      disabled={!isChecked} // Make it editable only when checkbox is unchecked
                      error={validationErrors.billing_city}
                    />
                  </div>
                </div>
                <br />
                {/* Third Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <TextField
                      id="billing_state_province"
                      value={data.billing_state_province}
                      onChange={(e) => onChange(e)}
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "State",
                        color: validationErrors.billing_state_province ? "red" : "",
                        style: {
                          color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                        disabled: isChecked, // All other fields should be disabled when checkbox is checked
                      }}
                      style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      disabled={!isChecked} // Make it editable only when checkbox is unchecked
                      error={validationErrors.billing_state_province}
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing Country<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <Select
                      id="billing_country"
                      value={isChecked ? selectedCountry : selectedCountryBill}
                      // value={ data.contact_country : formData?.billing_country}
                      onChange={handleChange2}
                      displayEmpty
                      variant="outlined"
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // adjust as needed
                            width: 200, // adjust as needed
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          },
                        },
                      }}
                      style={{
                        color: selectedCountryBill ? (selectedCountryBill === "Country" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        height: "40px",
                        width: "210px",
                        marginTop: "2%",
                        border: validationErrors.billing_country
                          ? "1px solid red"
                          : "",
                      }}
                      margin="dense"
                    >
                      <MenuItem value="" style={{ color: "#818E94",fontSize: "14px", }}>
                        {selectedCountryBill ? selectedCountryBill : "Country"}
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_id}
                          value={country.country_name}
                          style={{
                            color: "#818E94",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {country.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <TextField
                      id="billing_zip_code"
                      value={data.billing_zip_code}
                      onChange={(e) => onChange(e)}
                      variant="outlined"
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Pin/Zip Code",
                        color: validationErrors.billing_zip_code ? "red" : "",
                        style: {
                          color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                        disabled: isChecked, // All other fields should be disabled when checkbox is checked
                      }}
                      style={{
                        backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      disabled={!isChecked} // Make it editable only when checkbox is unchecked
                      error={validationErrors.billing_zip_code}
                    />
                  </div>
                </div>


                <br />

                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onClick={handleCheckboxChange}
                    color="primary"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  />
                  <span style={{ marginLeft: "1%", color: "#818E94" }}>
                    Same as Contact Address
                  </span>
                </div>

                <div style={{ flex: 1, marginTop: "3%" }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => setCurrentPage(1)}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Back
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#4779EF",
                          borderRadius: "10px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "20px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleCreate();
                        }}
                      >
                        Create
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
