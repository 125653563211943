import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../hooks/useAuth.js";

const PrivateRoute = ({ allowedRoles }) => {
  const auth = useContext(AuthContext);
  
  if (!auth.isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  console.log("ROLES : ",allowedRoles)
  if (!allowedRoles.includes(auth.role)) {
    return <Navigate to="/Dashboard" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;