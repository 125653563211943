import React, { useState, useEffect } from "react";
import { useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import indlogo from "../../asset/indlogo.png";
import background from "../../asset/background.png";
import show from "../../asset/show.png";
import hide from "../../asset/hide.png";
import "../../App.css";
import "../Login/Login.css";
import axios from "../../axios/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthContext from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField, InputAdornment } from "@mui/material";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@material-ui/core/ListItemText";
import CancelIcon from "@mui/icons-material/Cancel";
import {jwtDecode } from 'jwt-decode';
import * as jose from 'jose';
const secretKey = new TextEncoder().encode("+?pQ;Knz5VMNe$(!NQFRT*{E4}INiaDM");


const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const previousToastIdRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  

  useEffect(() => {
    const storedCredentials = localStorage.getItem("loginCredentials");
    if (storedCredentials && rememberMe) {
      const { email, password } = JSON.parse(storedCredentials);
      formik.setFieldValue("email", email);
      formik.setFieldValue("password", password);
    }
  }, [rememberMe]);

  const sendMailtoReset = (email) => {
    axios
      .get(`/forgotpassword?email=${email}`)
      .then((res) => {
        toast.success("Link has been sent to your email!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((error) => {
        toast.error("Failed to send reset link", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  }
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      var loginData = {
        email: values.email,
        password: values.password,
      };

      if (rememberMe) {
        // Store credentials and rememberMe in localStorage
        localStorage.setItem("loginCredentials", JSON.stringify(loginData));
        localStorage.setItem("rememberMe", rememberMe);
      } else {
        // Clear stored credentials and rememberMe if "Remember Me" is not checked
        localStorage.removeItem("loginCredentials");
        localStorage.removeItem("rememberMe");
      }

      setIsSubmitting(true);
      axios
        .post("/login", loginData)
        .then((response) => {
          
          const encryptedToken = response.data.token;

          // Decrypt the encrypted JWT token
          decryptToken(encryptedToken).then((data) => {
              console.log("Decrypted User Data:", data);
              
              // Prepare login data with decrypted information
              const loginData = {
                message: response.data.message,
                status: response.data.status,
                token: encryptedToken, // sending the encrypted token itself, or can update with decrypted data
                role: data.user_role,
                id: data.user_id,
                email: data.user_email,
                user_Name: data.user_name,
              };
               auth.login(loginData);
         console.log("login data",loginData)
          navigate("/");
          })
          async function decryptToken(encryptedJWT) {
              const { plaintext } = await jose.compactDecrypt(encryptedJWT, secretKey);
              const decodedPayload = JSON.parse(new TextDecoder().decode(plaintext));
              console.log("Decrypted Payload:", decodedPayload);
              return decodedPayload; // return the decrypted payload
          }
         
        })
        .catch((error) => {
          console.log("values :", error.toJSON().status);
          if (error.toJSON().status === 400 || error.toJSON().status === 401) {
            const errorMessage = "Wrong Email or Password!";
            if (previousToastIdRef.current) {
              toast.dismiss(previousToastIdRef.current);
            }
            previousToastIdRef.current = toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("*Please enter a valid email ID")
        .required("Enter an email"),
      password: Yup.string()
        .required("Required")
        .max(16, "Password should be less than 16 characters")
        .min(6, "Password must be at least 6 characters"),
    }),
  });

  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  };

  const handleForgotOpen = () => {
    setOpenForgot(true);
  };

  const handleForgotClose = () => {
    setOpenForgot(false);
  };

  const handleForgotPass = () => {
    navigate("/ForgotPassword")
  }
  const handleChangePass = () => {
    navigate("/PasswordReset")
  }
  const handleRequestResetLink = () => {
    sendMailtoReset(forgotEmail);
    setOpenForgot(false);
    setForgotEmail("")
  };

  return (
    <div style={backgroundStyle}>
      <div className="left-side-text">
        Game<span style={{ color: "#032254" }}>Mon</span>
        <br />
        <span className="left-side-text2">Smart way to test</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div className="login-container">
          <div>
            <h2 className="heading">Sign In</h2>
            <h5 className="heading2">
              {" "}
              No account?
              <span>
                <a className="heading3" href="mailto:#">
                  Contact Sales
                </a>
              </span>{" "}
            </h5>
            <form onSubmit={formik.handleSubmit}>
              <label className="label">Email or User name</label>
              <br />
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="input"
                autoComplete="off" 
              />
              {formik.touched.email && formik.errors.email && (
                <div className="errorText">{formik.errors.email}</div>
              )}
              <br />

              <label className="label">Password</label>
              <br />
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Must be at least 6 Characters"
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className="input"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-toggle-btn"
                >
                  <img
                    src={showPassword ? hide : show}
                    alt={showPassword ? "Hide" : "Show"}
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
              {/* {formik.touched.password && formik.errors.password && (
              <div className="errorText">{formik.errors.password}</div>
              )} */}
              <br />
              <div className="remember-me">
                <input
                  type="checkbox"
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label style={{ marginLeft: "1%" }}>Remember Me</label>
              </div>
              <br />
              <button
                type="submit"
                className="sign-in-button"
                disabled={isSubmitting}
              >
              {isSubmitting ? "Signing In..." : "SIGN IN"}
              </button>
              <ToastContainer
                toastStyle={{
                  marginTop: "4rem",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  alignContent: "center",
                  closeOnClick: true,
                  textAlign: "center",
                }}
                closeButton={false}
                hideProgressBar={false}
                autoClose={2000}
                position="top-center"
              />
            </form>
            <br />
            <div className="privacy">
              By continuing, you agree to accept our
              <br />
              <span style={{ color: "#83A8FF" }}>Privacy Policy</span> &{" "}
              <span style={{ color: "#83A8FF" }}>Terms of Service</span>
            </div>
            <br />
            <div className="forget-pass">
              <span style={{ color: "#83A8FF", cursor: "pointer" }} onClick={handleForgotOpen}>Forgot Password?</span>
              {/* <span style={{ color: "#83A8FF",cursor:"pointer" }} onClick={handleChangePass}>Reset Password</span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={indlogo} alt="Logo" className="ind-logo" />
          <h1 className="game-mon">GameMon</h1>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2024 Indium Software - All Rights Reserved.
      </div>
      <Dialog
        PaperProps={{
          sx: {
            width: "450px",
            height: "350px",
            backgroundColor: "#FFFFFF",
            overflow: "hidden"
          },
        }}
        open={openForgot}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
            overflow: "hidden"
          }}
        >
          <DialogTitle
            style={{
              fontSize: "20px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: "#000000",
              overflow: "hidden"
            }}
          >
            Forgot Password
          </DialogTitle>
        </div>
        <DialogContent style={{ marginTop: "-10px", overflow: "hidden" }}>
          <p style={{ color: "black", fontWeight: "400" }}>
            Please enter the email address you'd like to send your password reset information to
          </p>
          <br />
          <label>Enter email address</label>
          <br />
          <TextField
            style={{ marginTop: "1%", height: "20px", width: "400px" }}
            autoFocus
            margin="dense"
            id="forgot-email"
            type="email"
            fullWidth
            value={forgotEmail}
            autoComplete="off" // Disable autocomplete
            onChange={(e) => setForgotEmail(e.target.value)}
          />
          <br />
          <br />
          <DialogActions
            style={{
              marginTop: "4%",
              overflow: "hidden",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <List>
              <div
                style={{
                  color: "#FFFFFF",
                  background: "#4779EF",
                  borderRadius: "8px",
                  width: "300px",
                  height: "40px",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <ListItem
                  button
                  onClick={handleRequestResetLink}
                  autoComplete="off" // Disable autocomplete
                  style={{
                    fontFamily: "normal normal bold 16px/21px Product Sans",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    fontWeight:"600"
                  }}
                >
                  Request a password reset link
                </ListItem>
              </div>
            </List>
            <List>
              <div
                style={{
                  color: "#4779EF",
                  marginTop: "1%",
                  borderRadius: "8px",
                  width: "300px",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight:"600"
                }}
              >
                <ListItem
                  button
                  onClick={handleForgotClose}
                  style={{
                    fontFamily: "normal normal bold 16px/21px Product Sans",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  Back to Sign In
                </ListItem>
              </div>
            </List>
          </DialogActions>
        </DialogContent>
      </Dialog>

    </div>
  );
};

export default Login;
