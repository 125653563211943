import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import TextField from "@mui/material/TextField";
import { TextField, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider } from "@mui/material";
import "./Admin.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext} from "../../../../index";
import "./Admin.css";
import AuthContext from "../../../../hooks/useAuth";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FormAddDialog({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onRoleChange,
  onDateChange,
  formData,
  updateId,
}) {
  const { name, email, password, phone_number, role, access_end_date ,org_id } = data;
  const auth = useContext(AuthContext);
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  const [date, setDate] = useState(new Date());

  // const inputStyles = {
  //   width: '200px',
  //   height: '40px',
  // };

  // const calendarStyles = {
  //   width: '300px', }

  const sendMailtoCreatePassword = () => {
    const token = auth.token;
    if (!token) {
      toast.error("Authentication token is missing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    axios
      .get(
        `/createpasswordmail/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            email: email,
          },
        }
      )
      .then((res) => {
        console.log(res,"RES")
        // toast.success("Link has been sent to your email!", {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // });
        handleClose();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400){
          toast.warning("Password is already set", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        else if (error.response && error.response.status === 404){
          toast.error("Create Password Email - Failed ", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        handleClose();
      });
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
            // maxWidth: "xl",
            width: "27vw",
            height: "460px",
            backgroundColor: currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <DialogTitle
            style={{
              fontSize: "50px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
            // id="alert-dialog-title"
          >
            Create User
          </DialogTitle>
          <CancelIcon
            onClick={handleClose}
            style={{
              marginTop: "4.3%",
              cursor: "pointer",
              marginRight: "2.5vh",
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          />
        </div>
        <Divider
          style={{
            margin: "0vw 1.5vw",
            width: "22.5vw",
            backgroundColor: "grey",
          }}
        />
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
       
          <form>
            <Typography style={{ color: "#818E94" }}>Name</Typography>
            {/* <TextField
              id="name"
              value={name}
              onChange={(e) => onChange(e)}
              InputProps={{
                placeholder: "Enter Name",
                inputProps: {
                  style: {
                    color: currentTheme === "dark" ? "#818E94" : "black",textTransform:"capitalize"
                  },
                },
              }}
              // label="Name"
              variant="outlined"
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
              margin="dense"
            /> */}

            {/* vapt validation */}

            <TextField
              id="name"
              value={name}
              onChange={(e) => {
                const value = e.target.value;

                // Check for special characters and length
                const isValid = /^[A-Za-z\s]*$/.test(value) && value.length <= 12;

                if (isValid) {
                  // Proceed with valid input
                  onChange(e);  // Assuming onChange is your function to update state
                } else {
                  // Optional: You can display a message or handle invalid input here
                  console.log("Invalid name. Only letters and spaces are allowed, and the name must be under 12 characters.");
                }
              }}
              InputProps={{
                placeholder: "Enter Name",
                inputProps: {
                  style: {
                    color: currentTheme === "dark" ? "#818E94" : "black", 
                    textTransform: "capitalize"
                  },
                },
              }}
              variant="outlined"
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
              margin="dense"
            />

            <Typography style={{ color: "#818E94" }}>Email</Typography>
            <TextField
              id="email"
               value={formData?.email}
              type="email"
              onChange={onEmail}
              InputProps={{
                placeholder: "Enter Email",
                style: { color: currentTheme === "dark" ? "#818E94" : "black", },
              }}
              variant="outlined"
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
              margin="dense"
            />

            {/* <TextField
              id="password"
              value={password}
              onChange={(e) => onChange(e)}
              placeholder="Enter password"
              label="password"
              variant="outlined"
              style={{ marginLeft: "2vh" }}
              margin="dense"
            /> */}

            {/* <TextField
              id="phone_number"
              value={phone_number}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  onChange(e);
                }
              }}
              placeholder="Enter phone_number "
              label="phone_number"
              style={{ marginLeft: "2vh" }}
              variant="outlined"
              margin="dense"
            /> */}
            <Typography style={{ color: "#818E94" }}>Role</Typography>
            <FormControl
              style={{
                borderRadius: "5px",
                width: "22.5vw",
                marginTop: "2%",
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
              }}
            >
              {/* <InputLabel id="demo-simple-select-label"  style={{color: currentTheme === "dark" ? "#818E94" : "black",}} >Role</InputLabel> */}
              <Select
                name="role"
                value={role}
                // label="role"
                // InputLabelProps={{
                //   style: { color: "#818E94"}
                // }}
                style={{height: "40px",color: currentTheme === "dark" ? "#818E94" : "black",}}
                onChange={onRoleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxWidth: "200px",
                      color: currentTheme === "dark" ? "#818E94" : "black",
                      background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                    },
                  },
                }}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="tester">Tester</MenuItem>
                {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
              </Select>
            </FormControl>
            <Typography style={{ color: "#818E94",marginTop:"1%" }}>Access End Date</Typography>
            <TextField
            // style={{
            //   borderRadius: "5px",
            //   width: "22.5vw",
            //   marginTop: "2%",
            //   backgroundColor:
            //     currentTheme === "dark" ? "#292E33" : "#FFFFFF",
            // }}
              id="access_end_date"
              type="date"
              // label="End Date"
              value={access_end_date}
              onChange={onDateChange}
              variant="outlined"
              style={{ width: "22.5vw", marginTop: "2%" ,backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",}}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  padding: 8.5,
                  color: currentTheme === "dark" ? "#818E94" : "black",
                },
              }}
              margin="dense"
            />
          </form>
        </DialogContent>
              <DialogActions
                style={{
                  // marginLeft: "5%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <List >
                <div
              style={{
                color: "#FFFFFF",
                // marginTop: "8%",
                background: "#4779EF",
                borderRadius: "10px",
                width: "22.5vw",
                height: "40px",
                padding: "3px",
                fontSize: "15px",
              }}
            >
                  <ListItem
                    button
                    onClick={handleFormSubmit}
                    style={{
                      paddingLeft: "8vw",
                      fontFamily: "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Create User
                  </ListItem>
                  </div>
                </List>
              </DialogActions>
           
      </Dialog>
    </div>
  );
}
