// import React, { useState, useEffect } from "react";
// import { useRef, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import indlogo from "../../asset/indlogo.png";
// import background from "../../asset/background.png";
// import show from "../../asset/show.png";
// import hide from "../../asset/hide.png";
// import "../../App.css";
// import "../Login/Login.css";
// import axios from "../../axios/index";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import AuthContext from "../../hooks/useAuth";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const ResetPassword = () => {
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const auth = useContext(AuthContext);
//   const navigate = useNavigate();
//   const previousToastIdRef = useRef(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [rememberMe, setRememberMe] = useState(false);

//   useEffect(() => {
//     const storedCredentials = localStorage.getItem("loginCredentials");
//     if (storedCredentials && rememberMe) {
//       const { email, password } = JSON.parse(storedCredentials);
//       formik.setFieldValue("email", email);
//       formik.setFieldValue("password", password);
//     }
//   }, [rememberMe]);

//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       password: "",
//     },
//     onSubmit: async (values) => {
//       var loginData = {
//         email: values.email,
//         password: values.password,
//       };

//          if (rememberMe) {
//         // Store credentials and rememberMe in localStorage
//         localStorage.setItem("loginCredentials",  JSON.stringify(loginData));
//         localStorage.setItem("rememberMe", rememberMe);
//       } else {
//         // Clear stored credentials and rememberMe if "Remember Me" is not checked
//         localStorage.removeItem("loginCredentials");
//         localStorage.removeItem("rememberMe");
//       }

//       setIsSubmitting(true);
//       axios
//         .post("/login", loginData)
//         .then((response) => {
//           auth.login(response.data);
//           navigate("/");
//         })
//         .catch((error) => {
//           console.log("values :", error.toJSON().status);
//           if (error.toJSON().status === 400 || error.toJSON().status === 401) {
//             const errorMessage = "Wrong Email or Password!";
//             if (previousToastIdRef.current) {
//               toast.dismiss(previousToastIdRef.current);
//             }
//             previousToastIdRef.current = toast.error(errorMessage, {
//               position: toast.POSITION.BOTTOM_CENTER,
//             });
//           }
//         })
//         .finally(() => {
//           setIsSubmitting(false);
//         });
//     },

//     validationSchema: Yup.object().shape({
//       email: Yup.string()
//         .email("*Please enter a valid email ID")
//         .required("Enter an email"),
//       password: Yup.string()
//         .required("Required")
//         .max(16, "Password should be less than 16 characters")
//         .min(6, "Password must be at least 6 characters"),
//     }),
//   });

//   const backgroundStyle = {
//     backgroundImage: `url(${background})`,
//     backgroundSize: "cover",
//     backgroundRepeat: "no-repeat",
//     backgroundPosition: "center",
//     height: "100vh",
//     width: "100vw",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     position: "relative",
//   };
//   const handleLogout = () => {
//     auth.logout();
//     alert("Password changed successfully!");
//     navigate("/login");
//   };
//   return (
//     <div style={backgroundStyle}>
//       <div className="left-side-text">
//         Game<span style={{ color: "#032254" }}>Mon</span>
//         <br />
//         <span className="left-side-text2">Smart way to test</span>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100%",
//         }}
//       >
//         <div className="login-container">
//           <div>
//             <h2 className="heading">Change Password</h2>
//             <h5 className="heading2">
//               {" "}
//               No account?
//               <span>
//                 <a className="heading3" href="mailto:#">
//                   Contact Sales
//                 </a>
//               </span>{" "}
//             </h5>
//             <form>
//               <label className="label">Old Password</label>
//               <br />
//               <input
//                 type="text"
//                 placeholder="Old Password"
//                 name="old password"
//                 // value={formik.values.email}
//                 // onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 className="input"
//               />
//               {/* {formik.touched.email && formik.errors.email && (
//                 <div className="errorText">{formik.errors.email}</div>
//               )} */}
//               <br />
//               <label className="label">New Password</label>
//               <br />
//               <div className="password-input-container">
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   name="password"
//                   placeholder="Must be at least 6 Characters"
//                   onBlur={formik.handleBlur}
//                 //   value={formik.values.password}
//                 //   onChange={formik.handleChange}
//                   className="input"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="password-toggle-btn"
//                 >
//                   <img
//                     src={showPassword ? hide : show}
//                     alt={showPassword ? "Hide" : "Show"}
//                     style={{ width: "20px", height: "20px" }}
//                   />
//                 </button>
//               </div>
//               <label className="label">Confirm New Password</label>
//               <br />
//               <div className="password-input-container">
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   name="password"
//                   placeholder="Must be at least 6 Characters"
//                   onBlur={formik.handleBlur}
//                 //   value={formik.values.password}
//                 //   onChange={formik.handleChange}
//                   className="input"
//                 />
//                 <button
//                   type="button"
//                   onClick={() => setShowPassword(!showPassword)}
//                   className="password-toggle-btn"
//                 >
//                   <img
//                     src={showPassword ? hide : show}
//                     alt={showPassword ? "Hide" : "Show"}
//                     style={{ width: "20px", height: "20px" }}
//                   />
//                 </button>
//               </div>
//               <br />
//               <button
//                 type="submit"
//                 className="sign-in-button"
//                 // disabled={isSubmitting}
//                 onClick={handleLogout}
//               >
//                 Confirm Change
//               </button>
//               <ToastContainer
//                 toastStyle={{
//                   marginTop: "4rem",
//                   borderRadius: "10px",
//                   backgroundColor: "white",
//                   alignContent: "center",
//                   closeOnClick: true,
//                   textAlign: "center",
//                 }}
//                 closeButton={false}
//                 hideProgressBar={false}
//                 autoClose={2000}
//                 position="top-center"
//               />
//             </form>
//             <br />
//             <div className="privacy">
//               By continuing, you agree to accept our
//               <br />
//               <span style={{ color: "#83A8FF" }}>Privacy Policy</span> &{" "}
//               <span style={{ color: "#83A8FF" }}>Terms of Service</span>
//             </div>
//             <br />
//             {/* <div className="forget-pass">
//               <span style={{ color: "#83A8FF" }}>Forgot Password</span> |{" "}
//               <span style={{ color: "#83A8FF" }}>Reset Password</span>
//             </div> */}
//           </div>
//         </div>
//       </div>
//       <div className="bottom">
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <img src={indlogo} alt="Logo" className="ind-logo" />
//           <h1 className="game-mon">GameMon</h1>
//         </div>
//       </div>
//       <div className="copyright">
//       Copyright © 2024 Indium Software - All Rights Reserved.
//       </div>
//     </div>
//   );
// };

// export default ResetPassword;



//vapt fix
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import indlogo from "../../asset/indlogo.png";
import background from "../../asset/background.png";
import show from "../../asset/show.png";
import hide from "../../asset/hide.png";
import "../../App.css";
import "../Login/Login.css";
import axios from "../../axios/index";
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthContext from "../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
const [isSubmitting, setIsSubmitting] = useState(false);
const auth = useContext(AuthContext);
const navigate = useNavigate();
const previousToastIdRef = useRef(null);
const [showPassword, setShowPassword] = useState(false);

const validationSchema = Yup.object().shape({
oldPassword: Yup.string().required("Required"),
password: Yup.string()
.required("Required")
.min(6, "Password must be at least 6 characters")
.max(16, "Password should be less than 16 characters")
.matches(/[a-z]/, "Password must contain at least one lowercase letter")
.matches(/[A-Z]/, "Password must contain at least one uppercase letter")
.matches(/[0-9]/, "Password must contain at least one number")
.matches(/[!@#$%^&*(),.?\":{}|<>]/, "Password must contain at least one special character"),
confirmPassword: Yup.string()
.required("Required")
.oneOf([Yup.ref("password"), null], "Passwords must match")
.min(6, "Password must be at least 6 characters")
.max(16, "Password should be less than 16 characters"),
});

const formik = useFormik({
initialValues: {
oldPassword: "",
password: "",
confirmPassword: "",
},
validationSchema,
onSubmit: async (values) => {
if (values.oldPassword === values.password) {
toast.error("New password cannot be the same as the old password", {
position: toast.POSITION.BOTTOM_CENTER,
});
return;
}
setIsSubmitting(true);
axios
.post("/reset-password", values)
.then(() => {
auth.logout();
toast.success("Password changed successfully!");
navigate("/login");
})
.catch((error) => {
toast.error("Failed to change password");
})
.finally(() => {
setIsSubmitting(false);
});
},
});

return (
<div style={{ backgroundImage: `url(${background})`, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
<div className="login-container">
<h2 className="heading">Change Password</h2>
<form onSubmit={formik.handleSubmit}>
<label className="label">Old Password</label>
<input type="password" name="oldPassword" className="input" {...formik.getFieldProps("oldPassword")} />
{formik.touched.oldPassword && formik.errors.oldPassword && <div className="errorText">{formik.errors.oldPassword}</div>}

<label className="label">New Password</label>
<div className="password-input-container">
<input type={showPassword ? "text" : "password"} name="password" className="input" {...formik.getFieldProps("password")} />
<button type="button" onClick={() => setShowPassword(!showPassword)} className="password-toggle-btn">
<img src={showPassword ? hide : show} alt={showPassword ? "Hide" : "Show"} style={{ width: "20px", height: "20px" }} />
</button>
</div>
{formik.touched.password && formik.errors.password && <div className="errorText">{formik.errors.password}</div>}

<label className="label">Confirm New Password</label>
<div className="password-input-container">
<input type={showPassword ? "text" : "password"} name="confirmPassword" className="input" {...formik.getFieldProps("confirmPassword")} />
<button type="button" onClick={() => setShowPassword(!showPassword)} className="password-toggle-btn">
<img src={showPassword ? hide : show} alt={showPassword ? "Hide" : "Show"} style={{ width: "20px", height: "20px" }} />
</button>
</div>
{formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="errorText">{formik.errors.confirmPassword}</div>}

<button type="submit" className="sign-in-button" disabled={isSubmitting}>Confirm Change</button>
</form>
<ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} />
</div>
</div>
);
};

export default ResetPassword;
