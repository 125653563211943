import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Toolbar, Typography } from "@material-ui/core";
import AuthContext from "../../hooks/useAuth";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { ThemePreferenceContext } from "../../index";
import "./Header.css";
import axios from '../../axios/index.js';

function Header() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const name = auth.name;
  const role = auth.role;

  // const handleLogout = () => {
  //   auth.logout();
  //   navigate("/login");
  // };

  const handleLogout = () => {
    console.log("no token ", auth.token)
    auth.logout();
    navigate("/login");
    axios
      .get(
        "/logout",
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            
          },
        }
      )
      .then(() => {
        console.log("Logged out successfully");
        // auth.logout();
        // navigate("/login");
      })
      .catch((error) => {
        console.error("Logout failed", error);
      });
  };
  

  const handleProfileClick = () => {
    navigate("/Profile");
    handleClose();
  };

  const handleChangePass = () => {
    auth.logout();
    navigate("/PasswordReset")
  }
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      position="fixed"
      style={{
        backgroundColor: currentTheme,
        zIndex: 1100,
        width: "calc(100% - 16vw)",
        marginLeft: "16vw",
      }}
    >
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Typography
            variant="body"
            style={{
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              textAlign: "left",
              marginTop: 6,
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {window.location.pathname.split("/")}
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <select
            value={currentTheme}
            onChange={(e) => {
              setCurrentTheme(e.target.value);
            }}
            style={{ marginRight: "2rem" }}
          >
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select> */}
          <div className="wrapper" style={{ marginRight: "2rem" }}>
            <input
              type="checkbox"
              name="checkbox"
              className="switch"
              checked={currentTheme === "dark"}
              onChange={() => {
                setCurrentTheme(currentTheme === "light" ? "dark" : "light");
              }}
            />
          </div>
          {/* <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          > */}
            <ListItemButton
              sx={{
                borderRadius: 2,
                backgroundColor:
                  currentTheme === "dark" ? "#363C43" : "#FEFEFF",
                width: 40,
                marginRight: "8px",
              }}
            >
              <ListItemIcon>
                <NotificationsOutlinedIcon
                  sx={{
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    ml: -1.1,
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          {/* </Box> */}
          {/* <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          > */}
            <ListItemButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                borderRadius: 2,
                backgroundColor:
                  currentTheme === "dark" ? "#363C43" : "#FEFEFF",
                width: 40,
                marginRight: "8px",
              }}
            >
              <ListItemIcon>
                <PersonOutlineIcon
                  sx={{
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    ml: -1.1,
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          {/* </Box> */}
          <div
            className="username"
            style={{
              fontWeight: 600,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              textTransform: "capitalize",
            }} title={name}
          >
            {name}
            <br />
            <span
              className="role"
              style={{
                fontWeight: 400,
                color: "#818393",
                textTransform: "capitalize",
              }}
            >
              {" "}
              ({role === "super_admin" ? "Super Admin" : role}*)
            </span>
          </div>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                backgroundColor: currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                overflow: "visible",
                filter: "drop-shadow(0px 3px 6px #00000029)",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                  backgroundColor:
                    currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                },
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "#4779EF",
                  color: "#FFFFFF",
                },
                "& .MuiMenuItem-root": {
                  "&:hover": {
                    backgroundColor: "#4779EF",
                    color: "#FFFFFF",
                  },
                },
              },
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <MenuItem
              onClick={handleProfileClick}
              style={{
                fontSize: "16px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                fontWeight: "400",
              }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              onClick={handleChangePass}
              style={{
                fontSize: "16px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                fontWeight: "400",
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              style={{
                fontSize: "16px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                fontWeight: "400",
              }}
            >
              Logout
            </MenuItem>
          </Menu>


        </div>
      </Toolbar>
    </div>
  );
}

export default Header;
