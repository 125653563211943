import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Grid } from "@material-ui/core";
import FormEditDialog from "./Dialog";
import FormAddDialog from "./DialogAdd";
import CreatePricingBook from "./CreatePricingBook";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../../../axios/index";
import "./SuperAdmin.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, TextField, InputAdornment } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../index";
import AuthContext from "../../../../hooks/useAuth";
import EditPricingBook from "./EditPricingBook";

function App3() {
  const [users, SetUsers] = useState([]);
  const [orgInfo, SetOrgInfo] = useState([]);
  const [countries, SetCountries] = useState([]);
  const [openDel, setOpenDel] = React.useState(false);
  const [openPriceDel, setOpenPriceDel] = React.useState(false);
  const [OpenOrgInfo, setOpenOrgInfo] = React.useState(false);
  const [openPriceBook, setOpenPriceBook] = React.useState(false);
  const auth = useContext(AuthContext);
  // const onChanges = (e) => {
  //   const enteredEmail = e.target.value;
  //   setIsValidEmail(enteredEmail.includes('@'));
  //   // ... rest of your onChange logic
  // };
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const initialValue = {
    org_name: "",
    admin_email_id: "",
    pc_first_name: "",
    pc_last_name: "",
    contact_address_line_1: "",
    contact_address_line_2: "",
    contact_city: "",
    contact_state_province: "",
    contact_zip_code: "",
    contact_country: "",
    phone_country_code: "",
    phone_number: "",
    created_at: "",
    subscription_type: "",
    status: " ",
    bc_first_name: "",
    bc_last_name: "",
    billing_address_line_1: "",
    billing_address_line_2: "",
    billing_city: "",
    billing_state_province: "",
    billing_zip_code: "",
    billing_country: "",
    tax_id: "",
  };
  const PricingBook = {
    org_id: "",
    pb_name: "",
    effective_from: "",
    currency: "",
    created_by: "",
    pricing_category_id: "",
    pricing_category_name: "",
    free_usage_per_month: "",
    unit_type: "",
    unit_price: "",
    min_guarantee_unit: "0",
    unit_price_post_guarantee_unit: "0",
    due_date_threshold: "0",
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openAddPriceBook, setOpenAddPriceBook] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updatePbId, setUpdatePbId] = useState("");
  const [formData, setFormData] = useState(initialValue);
  const [searchTerm, setSearchTerm] = useState("");
  const [selecteditem, setSelecteditem] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [priceBook, setPriceBook] = React.useState([]);
  const [openEditPriceBook, setOpenEditPriceBook] = React.useState(false);
  const [pricingData, setPricingData] = useState(PricingBook);
  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  const handleClickOpenPrice = () => {
    setOpenAddPriceBook(true);
  };
  const handleEditOpenPrice = () => {
    setOpenEditPriceBook(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
    setFormData(initialValue);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setFormData(initialValue);
  };
  const handleAddPriceClose = () => {
    setOpenAddPriceBook(false);
    setPricingData(PricingBook);
  };
  const handleEditPriceClose = () => {
    setOpenEditPriceBook(false);
    setPricingData(PricingBook);
  };

  const handleClickDelOpen = () => {
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };
  const handlePriceDelOpen = () => {
    setOpenPriceDel(true);
  };

  const handlePriceDelClose = () => {
    setOpenPriceDel(false);
  };

  const handleOrgInfoOpen = (userId) => {
    setLoading(true);
    getOrgInfo(userId);
    setOpenOrgInfo(true);
  };

  useEffect(() => {
    // console.log("Updated pricing data:", pricingData);
  }, [pricingData]);


  const [orgID, SetOrgID] = useState(0)
  const handlePriceBookOpen = (params) => {
    // console.log("HANDLE PRICE",params)
    // console.log("HANDLE PRICE ID",params.data.id)
    // console.log("HANDLE PRICE VALUE",params.value)
    let org_id = (params.data.id);
    SetOrgID(org_id);
    getPriceBook(org_id);
    setOpenPriceBook(true);
  };
  const handleOrgInfoClose = () => {
    setOpenOrgInfo(false);
    SetOrgInfo([]);
  };
  const handlePriceBookClose = () => {
    setOpenPriceBook(false);
    setPriceBook([])
  };

  useEffect(() => {
    getUsers();
    // getCountries();
    // getPriceBook();
  }, []);

  const filteredUsers = users?.filter(data => data.status_org === "Y" || data.status_org === null);

  const rowData = filteredUsers?.map((data) => {
    const date = new Date(data?.created_at);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return {
      id: data?.org_id,
      org_name: data?.org_name,
      pc_first_name: data?.pc_first_name,
      pc_last_name: data?.pc_last_name,
      admin_email_id: data?.admin_email_id,
      contact_address_line_1: data?.contact_address_line_1,
      contact_address_line_2: data?.contact_address_line_2,
      contact_city: data?.contact_city,
      contact_state_province: data?.contact_state_province,
      contact_zip_code: data?.contact_zip_code,
      contact_country: data?.contact_country,
      phone_country_code: data?.phone_country_code,
      phone_number: data?.phone_number,
      created_at: formattedDate,
      subscription_type: data?.subscription_type,
      status: data?.status,
      bc_first_name: data?.bc_first_name,
      bc_last_name: data?.bc_last_name,
      billing_address_line_1: data?.billing_address_line_1,
      billing_address_line_2: data?.billing_address_line_2,
      billing_city: data?.billing_city,
      billing_state_province: data?.billing_state_province,
      billing_zip_code: data?.billing_zip_code,
      billing_country: data?.billing_country,
      tax_id: data?.tax_id,
    };
  });


  const rowData2 = priceBook?.map((data) => {
    const date = new Date(data?.created_at);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    const date1 = new Date(data?.effective_from);
    const day1 = date1.getDate();
    const month1 = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date1);
    const year1 = date1.getFullYear();
    const formattedDate1 = `${day1}-${month1}-${year1}`;

    return {
      id: data?.org_id,
      pb_id: data?.pb_id,
      pb_name: data?.pb_name,
      effective_from: formattedDate1,
      effective_to: data?.effective_to,
      currency: data?.currency,
      created_at: formattedDate,
      created_by: data?.org_id,
      pricing_category_id: data?.pricing_category_id,
      pricing_category_name: data?.pricing_category_name,
      free_usage_per_month: data?.free_usage_per_month,
      unit_type: data?.unit_type,
      unit_price: data?.unit_price,
      min_guarantee_unit: data?.min_guarantee_unit,
      unit_price_post_guarantee_unit: data?.unit_price_post_guarantee_unit,
      due_date_threshold: data?.due_date_threshold,
      status_pb: data?.status_pb,
    };
  });


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);

  const defaultColDefsPricing = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      cellStyle: { fontSize: "15px", width: 250 },
    };
  }, []);

  const capitalizeFirstLetter = (value) => {
    if (!value) return "";
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const columnDefs = [
    {
      headerName: "Organization Name",
      field: "org_name",
      flex: 1,
      maxWidth: 600,
      unSortIcon: true,
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Email",
      field: "admin_email_id",
      flex: 1.5,
      maxWidth: 350,
      unSortIcon: true,
    },
    {
      headerName: "Date Joined",
      field: "created_at",
      flex: 1,
      maxWidth: 700,
      unSortIcon: true,
    },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <EditIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
            onClick={() => {
              handleUpdate(params.data, params.value);
            }}
            titleAccess="Edit Org"
          ></EditIcon>
          <DeleteIcon
            variant="outlined"
            color="secondary"
            style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
            onClick={(e) => {
              setUpdateId(params.value);
              handleClickDelOpen();
            }}
            titleAccess="Delete Org"
          ></DeleteIcon>
          <InfoOutlinedIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "green" }}
            onClick={() => handleOrgInfoOpen(params.data.id)}
            titleAccess="Org Info"
          ></InfoOutlinedIcon>
          <AttachMoneyOutlinedIcon
            variant="outlined"
            color="primary"
            style={{ margin: "10px", cursor: "pointer", color: "green" }}
            onClick={() => {
              handlePriceBookOpen(params);
            }}
            titleAccess="Pricing Book"
          ></AttachMoneyOutlinedIcon>
        </div>
      ),
    },
  ];

  const filteredData = rowData
    .filter((data) =>
      data.org_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((data) => data.phone_number > 0)
    .sort((a, b) => a.org_name.localeCompare(b.org_name));

  const paginationNumberFormatter = useCallback((params) => {
    // return '[' + params.value.toLocaleString() + ']';
    return params.value;
  }, []);
  //fetching user data from server


  const columnDefsPricing = [
    {
      headerName: "Pricing Book Name",
      field: "pb_name",
      flex: 1,
      minWidth: 200,
      unSortIcon: true,
      cellStyle: (params) => {
        return params.data.status_pb === 'N' ? { color: 'red' } : {};
      },
      valueFormatter: (params) => capitalizeFirstLetter(params.value),
    },
    {
      headerName: "Pricing Category",
      field: "pricing_category_name",
      flex: 1.5,
      minWidth: 150,
      unSortIcon: true,
      cellStyle: (params) => {
        return params.data.status_pb === 'N' ? { color: 'red' } : {};
      },
    },
    {
      headerName: "Effective From",
      field: "effective_from",
      flex: 1,
      minWidth: 150,
      unSortIcon: true,
      cellStyle: (params) => {
        return params.data.status_pb === 'N' ? { color: 'red' } : {};
      },
    },
    {
      headerName: "Currency",
      field: "currency",
      flex: 1,
      minWidth: 100,
      unSortIcon: true,
      cellStyle: (params) => {
        return params.data.status_pb === 'N' ? { color: 'red' } : {};
      },
    },
    {
      headerName: "Created On",
      field: "created_at",
      flex: 1,
      minWidth: 150,
      unSortIcon: true,
      cellStyle: (params) => {
        return params.data.status_pb === 'N' ? { color: 'red' } : {};
      },
    },
    {
      headerName: "Actions",
      field: "id",
      cellRendererFramework: (params) => {
        return params.data.status_pb === 'N' ? null : (
          <div>
            <EditIcon
              variant="outlined"
              color="primary"
              style={{ margin: "10px", cursor: "pointer", color: "#278EF1" }}
              onClick={() => {
                handleUpdatePriceBook(params.data, params.value);
              }}
              titleAccess="Edit Pricing Book"
            />
            <DeleteIcon
              variant="outlined"
              color="secondary"
              style={{ margin: "10px", cursor: "pointer", color: "#FF3E63" }}
              onClick={() => {
                setUpdatePbId(params.data.pb_id);
                handlePriceDelOpen();
              }}
              titleAccess="Delete Price Book"
            />
          </div>
        );
      },
    },
  ];

  const filteredDatas = rowData2
    .filter((data) =>
      data.pb_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));



  const paginationNumberFormatter1 = useCallback((params) => {
    // return '[' + params.value.toLocaleString() + ']';
    return params.value;
  }, []);
  //fetching user data from server

  const getUsers = () => {
    axios
      .get("/organization", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        SetUsers(res.data.data);
      });
  };
  const getCountries = () => {
    axios
      .get("/countrydetails", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        SetCountries(res.data.data);
      });
  };

  const getPriceBook = (org_id) => {
    // console.log("USER ID", org_id);
    axios
      .get(`/bookpricing/${org_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setPriceBook(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching price book:", error);
      });
  };

  const getOrgInfo = (userId) => {
    axios
      .get(`/organization/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        SetOrgInfo(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching organization information:", error);
      });
  };

  const onEmail = (e) => {
    const { value, id } = e.target;
    if (value.includes("@") && value.includes(".")) {
      setFormData({ ...formData, [id]: value });
    }
  };

  const onChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const onChangePrice = (e) => {
    // console.log("E value",e.target)
    const { id, value } = e.target;
    setPricingData((prevPricingData) => ({
      ...prevPricingData,
      [id]: value,
    }));
  };
  const onChangeCurrency = (e) => {
    setPricingData({ ...pricingData, currency: e });
  }
  const onChangeUnitType = (type) => {
    setPricingData({ ...pricingData, unit_type: type });
  }
  const onChangeCategoryType = (category) => {
    setPricingData({ ...pricingData, pricing_category_name: category });
  }
  const onEffectiveDateChange = (e) => {
    const selectedDate = e.target.value;
    setPricingData({ ...pricingData, effective_from: selectedDate });
  };
  const onRoleChange = (e) => {
    setFormData({ ...formData, role: e.target.value });
  };
  const onDateChange = (e) => {
    setFormData({ ...formData, access_end_date: e.target.value });
  };

  const onChange2 = (country) => {
    setFormData({ ...formData, billing_country: country });
  };
  const onChange3 = (country2) => {
    // console.log("contact", country2);
    setFormData({ ...formData, contact_country: country2 });
  };

  const onChangePhone = (value, country, event, formattedValue) => {
    // Extract country code and phone number from the value
    const countryCode = country.dialCode;
    const phoneNumber = value.replace(country.dialCode, "");

    // Update the formData state with the new phone number value
    setFormData((prevState) => ({
      ...prevState,
      phone_country_code: countryCode,
      phone_number: phoneNumber,
    }));
  };

  //setting update row data to form data and opening pop up window
  const handleUpdate = (oldData, id) => {
    setUpdateId(id);
    delete oldData.id;
    delete oldData.number_of_days_left;
    delete oldData.status;
    delete oldData.user_since;
    setFormData(oldData);
    handleEditOpen();
  };

  // const handleUpdatePriceBook = (data, value) => {
  //   console.log("Editing data:", data);
  //   // delete value.org_id
  //   setUpdatePbId(data.pb_id);
  //   setPricingData(data);
  //   handleEditOpenPrice();
  // };

  const handleUpdatePriceBook = (data, pb_id) => {
    setUpdatePbId(data.pb_id);
    // console.log("PB ID:",pb_id)
    // console.log("OLD PB-ID:",data.pb_id)
    // console.log(setUpdatePbId,"SETTING")
    // delete data.org_id
    // delete data.pb_id;
    // delete data.pb_name;
    // delete data.currency;
    setPricingData(data);
    handleEditOpenPrice();
  };


  //deleting a user
  const handleDelete = () => {
    handleDelClose();
    const token = auth.token;
    if (!token) {
      toast.error("Authentication token is missing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    axios
      .put(`/organizationdelete/${updateId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          params: {
            org_id: updateId,
          },
        })
      .then((res) => {
        toast.success("Deleted Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getUsers();
      })
      .catch((error) => {
        console.error("Error deleting organization:", error);
        toast.error("Failed to delete organization!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  //deleting price book
  const handleDeletePricingBook = () => {
    handlePriceDelClose();
    const token = auth.token;
    if (!token) {
      toast.error("Authentication token is missing", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    axios
      .put(
        `/bookpricingdelete/${updatePbId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            pb_id: updatePbId,
          },
        }
      )
      .then((res) => {
        toast.success("Pricing Book Deleted Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getPriceBook(orgID);
      })
      .catch((error) => {
        console.error("Error deleting pricing book:", error);
        toast.error("Failed to delete pricing book!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

   const sendMailtoCreatePassword = () => {
     const token = auth.token;
     if (!token) {
       toast.error("Authentication token is missing", {
         position: toast.POSITION.TOP_RIGHT,
       });
       return;
     }
     console.log(formData.admin_email_id, "EMAIL DATA")
     axios
       .get(
         `/createpasswordmail?email=${formData.admin_email_id}`,
         {
           headers: {
             Authorization: `Bearer ${token}`,
             "Content-Type": "application/json",
           },
 
         }
       )
       .then((res) => {
         console.log(res, "RES")
         toast.success("Link has been sent to your email!", {
           position: toast.POSITION.TOP_RIGHT,
         });
        //  handleClose();
       })
       .catch((error) => {
         if (error.response && error.response.status === 400) {
           toast.warning("User Already Exists, Use your Old Credentials!", {
             position: toast.POSITION.TOP_RIGHT,
           });
         }
         else if (error.response && error.response.status === 404) {
           toast.error("Create Password Email - Failed ", {
             position: toast.POSITION.TOP_RIGHT,
           });
         }
         // handleClose();
       });
   };


  const handleFormAddSubmit = async () => {
    const values = JSON.stringify(formData);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      };
      await axios.post(`/organization`, values, config);
      // await axios.post(`/countrydetails`, values, config);
      handleAddClose();
      toast.success("User Added Successfully !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      sendMailtoCreatePassword();
      getUsers();
    } catch (error) {
      handleAddClose();
      toast.error("Enter all credentails !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlePriceAddSubmit = () => {
    const user_id = auth.id;
    const categoryId = pricingData.pricing_category_name === "Tiered Pricing" ? 2 : 1;

    // Update pricingData with the correct pricing_category_id
    pricingData.created_by = user_id;
    pricingData.org_id = orgID;
    pricingData.pricing_category_id = categoryId;

    const values = JSON.stringify(pricingData);

    axios
      .post(`/bookpricing`, values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleAddPriceClose();
        getPriceBook(orgID);
        toast.success("Pricing Book Created Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error("Error creating pricing book:", error);
        handleAddPriceClose();
        toast.error("Failed to create pricing book!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  const handleFormEditSubmit = () => {
    const values = JSON.stringify(formData);
    axios
      .put(`/organization/${updateId}`, values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        params: {
          id: updateId,
        },
      })
      .then((res) => {
        handleEditClose();
        getUsers();
        toast.success("User Edited Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(() => {
        toast.error("Elements are Missing !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handlePriceEditSubmit = () => {
    // delete pricingData.pb_name
    const user_id = auth.id;
    const categoryId = pricingData.pricing_category_name === "Tiered Pricing" ? 2 : 1;
    pricingData.pricing_category_id = categoryId;
    if (pricingData.pricing_type === "Flat Price") {
      delete pricingData.min_guarantee_unit;
      delete pricingData.unit_price_post_guarantee_unit;
      delete pricingData.due_date_threshold;
    }
    // Update pricingData with the correct pricing_category_id
    pricingData.created_by = user_id;
    delete pricingData.id;
    delete pricingData.effective_to;
    delete pricingData.pb_id;
    delete pricingData.created_at
    // console.log("PRICING DATA",pricingData)
    const values = JSON.stringify(pricingData);
    // console.log("before delete",values)

    // console.log("PRCING",pricingData)
    // console.log("after delete",values)
    // console.log("UD PB ID",updatePbId)
    // console.log("VALUES PBS",values)
    axios
      .put(`/bookpricing/${updatePbId}`, values, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        params: {
          pb_id: updatePbId,
        },
      })
      .then((res) => {
        handleEditPriceClose()
        getPriceBook(orgID);
        toast.success("Pricing Book Edited Successfully !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(() => {
        toast.error("Elements are Missing !", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (<>
    <div
      style={{
        width: "81vw",
        // marginTop: "2%",
        position: "fixed",
        // background: "skyblue",
        // height: "75vh",
      }}
    >
      <main>
        <div
          className="ag-theme-alpine"
          style={{
            height: "75vh",
            // marginTop: "%",
            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0 12px",
              paddingTop: "20px",
            }}
          >
            <div>
              <TextField
                variant="outlined"
                type="text"
                placeholder={"Search Organization"}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{
                          color:
                            currentTheme === "dark" ? "#99999F" : "#818E94",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  height: "40px",
                  // width: "100%",
                  // marginLeft: '9px',
                  "& input::placeholder": {
                    textOverflow: "ellipsis !important",
                    color: currentTheme === "dark" ? "#99999F" : "#818E94",
                    opacity: 1,
                  },
                  "& .MuiInputBase-root": {
                    height: "40px !important",
                    backgroundColor:
                      currentTheme === "dark" ? "#292E33" : "white",
                    borderRadius: "8px",
                    color: currentTheme === "dark" ? "#99999F" : "#000000",
                  },
                }}
              />
              {/* <Typography
                style={{
                  marginLeft: "2%",
                  fontSize: "14px",
                  marginTop: "1vh",
                  color: currentTheme === "dark" ? '#729CFF' : "#4779EF",
                  fontWeight:'600',
                }}
              >
                Export  <span style={{marginLeft:'7%'}}>Print</span> 
              </Typography> */}
            </div>

            <List onClick={handleClickOpen}>
              <div
                style={{
                  color: "#FFFFFF",
                  background: "#4779EF",
                  borderRadius: "7px",
                  width: "140px",
                  marginTop: "-8px",
                  height: "40px",
                }}
              >
                <ListItem button>
                  <div
                    style={{
                      marginTop: "-4px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          type="body2"
                          style={{
                            color: "white",
                            // marginBottom: "13px",
                          }}
                        >
                          Create Org
                        </Typography>
                      }
                    ></ListItemText>
                    <AddIcon style={{ marginTop: "3px" }} />
                  </div>
                </ListItem>
              </div>
            </List>
          </Grid>
          <br></br>
          <div
            style={{
              border:
                currentTheme === "dark"
                  ? "1px solid #646667"
                  : "1px solid #DFDFDF",
            }}
            className={
              currentTheme === "dark"
                ? "ag-theme-alpine-dark"
                : "ag-theme-alpine"
            }
            id="table-grid"
          >
            {/* <div style={gridStyle}> */}
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={filteredData}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
            />
            {/* </div> */}
          </div>
        </div>

        <FormEditDialog
          open={openEdit}
          handleClose={handleEditClose}
          data={formData}
          onChange={onChange}
          onEmail={onEmail}
          onRoleChange={onRoleChange}
          onChangePhone={onChangePhone}
          onChange2={onChange2}
          onDateChange={onDateChange}
          updateId={updateId}
          handleFormSubmit={handleFormEditSubmit}
          onChange3={onChange3}
        />
        <FormAddDialog
          open={openAdd}
          handleClose={handleAddClose}
          data={formData}
          onChange={onChange}
          onRoleChange={onRoleChange}
          onChangePhone={onChangePhone}
          onChange2={onChange2}
          onDateChange={onDateChange}
          onEmail={onEmail}
          updateId={updateId}
          handleFormSubmit={handleFormAddSubmit}
          onChange3={onChange3}
        />

        <Dialog
          PaperProps={{
            sx: {
              width: "450px",
              height: "225px",
              backgroundColor:
                currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
            },
          }}
          open={openDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DialogTitle
              style={{
                fontSize: "20px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            // id="alert-dialog-title"
            >
              Delete Organization
            </DialogTitle>
            <CancelIcon
              onClick={handleDelClose}
              style={{
                marginTop: "4.3%",
                cursor: "pointer",
                marginRight: "2.5vh",
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            />
          </div>
          <Divider
            style={{
              margin: "0vw 1.5vw",
              width: "85%",
              backgroundColor: "grey",
            }}
          />
          <DialogContent>
            <h4 style={{ color: "grey" }}>
              Are you sure, you want to delete the Organization?
            </h4>
          </DialogContent>
          <DialogActions
            style={{
              marginLeft: "1vw",
              display: "flex",
              justifyContent: "row",
              marginRight: "5vw",
              marginTop: "125px",
              // marginBottom:'1vh',
              position: "fixed",
              gap: "20px",
            }}
          >
            <List onClick={handleDelClose}>
              <div
                style={{
                  color: "#03AC13",
                  marginTop: "8%",
                  border: "2px solid #03AC13",
                  boxShadow: "white",
                  borderRadius: "8px",
                  width: "160px",
                  height: "40px",
                  padding: "7px",
                  fontSize: "15px",
                }}
              >
                <ListItem
                  button
                  onClick={handleDelClose}
                  style={{
                    paddingLeft: "30%",
                    marginTop: "-4%",
                    fontFamily: "normal normal bold 16px/21px Product Sans",
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  Cancel
                </ListItem>
              </div>
            </List>
            <List>
              <div
                style={{
                  color: "#FFFFFF",
                  marginTop: "8%",
                  background: "red",
                  borderRadius: "8px",
                  width: "160px",
                  height: "40px",
                  padding: "3px",
                  fontSize: "15px",
                }}
              >
                <ListItem
                  button
                  onClick={handleDelete}
                  style={{
                    paddingLeft: "30%",
                    fontFamily: "normal normal bold 16px/21px Product Sans",
                  }}
                  color="secondary"
                  variant="outlined"
                >
                  Proceed
                </ListItem>
              </div>
            </List>
          </DialogActions>
        </Dialog>
        <Dialog
          PaperProps={{
            style: {
              boxShadow: "none",
              // maxWidth: "xl",
              minWidth: "650px",
              // width: "40vw",
              height: "280px",
              backgroundColor:
                currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
            },
          }}
          open={OpenOrgInfo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DialogTitle
              style={{
                fontSize: "20px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            // id="alert-dialog-title"
            >
              Organization Info
            </DialogTitle>
            <CancelIcon
              onClick={handleOrgInfoClose}
              style={{
                marginTop: "3%",
                cursor: "pointer",
                marginRight: "2.5vh",
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            />
          </div>
          <Divider
            style={{
              margin: "0vw 1.5vw",
              width: "90%",
              backgroundColor: "grey",
            }}
          />
          {loading ? ( // Conditional rendering based on loading state
            (<div className="loader-container">
              <div className="loader"></div>
            </div>) // Render loader while loading
          ) : (
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                marginTop: "-5%",
              }}
            >
              {orgInfo.map((orginfo) => (
                <form>
                  {/* First Row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "20px", // Add margin to separate from the next row
                    }}
                  >
                    {/* Field 1 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                          }}
                        >
                          Organization Name:
                        </span>
                        <br />
                        {orginfo.org_name}
                      </Typography>
                    </div>
                    {/* Field 2 */}
                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                          }}
                        >
                          {" "}
                          Email:{" "}
                        </span>
                        <br />
                        {orginfo.admin_email_id}
                      </Typography>
                    </div>
                    {/* Field 3 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                          }}
                        >
                          Contact No:{" "}
                        </span>
                        <br />
                        +{orginfo.phone_country_code} {orginfo.phone_number}
                      </Typography>
                    </div>
                  </div>
                  <br />
                  {/* Second Row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Field 4 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                          }}
                        >
                          {" "}
                          Country:{" "}
                        </span>
                        <br />
                        {/* {orginfo.contact_country.charAt(0).toUpperCase() +
                          orginfo.contact_country.slice(1).toLowerCase()} */}
                        {orginfo.contact_country}
                      </Typography>
                    </div>
                    {/* Field 5 */}
                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
                          }}
                        >
                          Date Joined:{" "}
                        </span>
                        <br />
                        {(() => {
                          const date = new Date(orginfo.created_at);
                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = new Intl.DateTimeFormat("en-US", {
                            month: "short",
                          }).format(date);
                          const year = date.getFullYear();
                          return `${day}-${month}-${year}`;
                        })()}
                      </Typography>
                    </div>
                    {/* Field 6 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                          }}
                        >
                          {/* Current Plan:{" "} */}
                        </span>
                        <br />
                        {/* not yet added */}
                      </Typography>
                    </div>
                  </div>
                </form>
              ))}
            </DialogContent>
          )}
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              boxShadow: "none",
              // maxWidth: "xl",
              minWidth: "90vw",
              // width: "40vw",
              maxHeight: "500px",
              // maxHeight: "600px",
              backgroundColor:
                currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
            },
          }}
          open={openPriceBook}
          onClose={handlePriceBookClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // marginTop: "10px",
            }}
          >
            <DialogTitle
              style={{
                fontSize: "20px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            // id="alert-dialog-title"
            >
              Pricing Book Info
            </DialogTitle>

            <CancelIcon
              onClick={()=>{
                handlePriceBookClose();
              }}
              style={{
                marginTop: "1.8%",
                cursor: "pointer",
                marginRight: "2.5vh",
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            />
          </div>
          <Divider
            style={{
              margin: "0vw 1.5vw",
              width: "95%",
              backgroundColor: "grey",
            }}
          />
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginTop: "-1%",
            }}
          >
            <div
              className="ag-theme-alpine"
              style={{
                height: "80vh",
                width: "99%",
                // marginTop: "-3%",
                backgroundColor: "transparent",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "0 12px",
                  // paddingTop: "40px",
                }}
              >
                <div>
                  <TextField
                    variant="outlined"
                    type="text"
                    placeholder={"Search Pricing Book"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              color:
                                currentTheme === "dark" ? "#99999F" : "#818E94",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      height: "40px",
                      // width: "100%",
                      // marginLeft: '9px',
                      "& input::placeholder": {
                        textOverflow: "ellipsis !important",
                        color: currentTheme === "dark" ? "#99999F" : "#818E94",
                        opacity: 1,
                      },
                      "& .MuiInputBase-root": {
                        height: "40px !important",
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "white",
                        borderRadius: "8px",
                        color: currentTheme === "dark" ? "#99999F" : "#000000",
                      },
                      marginTop: "25px",
                      marginLeft: "-14px"
                    }}
                  />
                  {/* <Typography
                style={{
                  marginLeft: "2%",
                  fontSize: "14px",
                  marginTop: "1vh",
                  color: currentTheme === "dark" ? '#729CFF' : "#4779EF",
                  fontWeight:'600',
                }}
              >
                Export  <span style={{marginLeft:'7%'}}>Print</span> 
              </Typography> */}
                </div>

                <List onClick={handleClickOpenPrice}>
                  <div
                    style={{
                      color: "#FFFFFF",
                      background: "#03AC13",
                      borderRadius: "7px",
                      width: "220px",
                      marginTop: "15px",
                      height: "40px",
                    }}
                  >
                    <ListItem button>
                      <div
                        style={{
                          marginTop: "-4px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ListItemText onClick={handleClickOpenPrice}
                          primary={
                            <Typography
                              type="body2"
                              style={{
                                color: "white",
                                // marginBottom: "13px",
                              }}
                            >
                              Add New Pricing Book
                            </Typography>
                          }
                        ></ListItemText>
                        <AddIcon style={{ marginTop: "3px" }} />
                      </div>
                    </ListItem>
                  </div>
                </List>
              </Grid>
              <br></br>
              <div
                style={{
                  border:
                    currentTheme === "dark"
                      ? "1px solid #646667"
                      : "1px solid #DFDFDF",
                }}
                className={
                  currentTheme === "dark"
                    ? "ag-theme-alpine-dark"
                    : "ag-theme-alpine"
                }
                id="table-grid"
              >
                {/* <div style={gridStyle}> */}
                <AgGridReact
                  columnDefs={columnDefsPricing}
                  defaultColDef={defaultColDefsPricing}
                  rowData={filteredDatas}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter1}
                />
                {/* </div> */}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        <CreatePricingBook
          open={openAddPriceBook}
          handleClose={handleAddPriceClose}
          data={pricingData}
          onChange={onChangePrice}
          onChangeCurrency={onChangeCurrency}
          onChangeUnitType={onChangeUnitType}
          onEffectiveDateChange={onEffectiveDateChange}
          onChangeCategoryType={onChangeCategoryType}
          // onRoleChange={onRoleChange}
          // onChangePhone={onChangePhone}
          // onChange2={onChange2}
          // onDateChange={onDateChange}
          // onEmail={onEmail}
          updateId={updateId}
          updatePbId={updatePbId}
          handleFormSubmit={handlePriceAddSubmit}
        // onChange3={onChange3}
        />
        <EditPricingBook
          open={openEditPriceBook}
          handleClose={handleEditPriceClose}
          data={pricingData}
          onChange={onChangePrice}
          onChangeCurrency={onChangeCurrency}
          onChangeUnitType={onChangeUnitType}
          onEffectiveDateChange={onEffectiveDateChange}
          onChangeCategoryType={onChangeCategoryType}
          // onEmail={onEmail}
          // onRoleChange={onRoleChange}
          // onChangePhone={onChangePhone}
          // onChange2={onChange2}
          // onDateChange={onDateChange}
          updateId={updateId}
          updatePbId={updatePbId}
          handleFormSubmit={handlePriceEditSubmit}
        // onChange3={onChange3}
        />

        <Dialog
          PaperProps={{
            sx: {
              width: "450px",
              height: "230px",
              backgroundColor:
                currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
            },
          }}
          open={openPriceDel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DialogTitle
              style={{
                fontSize: "20px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            // id="alert-dialog-title"
            >
              Delete Pricing Book
            </DialogTitle>
            <CancelIcon
              onClick={handlePriceDelClose}
              style={{
                marginTop: "4.3%",
                cursor: "pointer",
                marginRight: "2.5vh",
                color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
              }}
            />
          </div>
          <Divider
            style={{
              margin: "0vw 1.5vw",
              width: "90%",
              backgroundColor: "grey",
            }}
          />
          <DialogContent>
            {/* <div
                  style={{ flex: 1, marginRight: "10px", marginTop: "-10px" }}
                >
                  <Typography style={{ color: "#818E94" }}>
                    <span
                      style={{
                        color:
                          currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif`,
                      }}
                    >
                      Select the Pricing Book:
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <br />
                    <Select
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height: "10px",
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        width: "250px",
                        height: "42px",
                        marginTop: "4%",
                      }}
                      margin="dense"
                    ></Select>
                  </Typography>
                </div> */}
            {/* <br /> */}
            <h4 style={{ color: "grey", marginTop: "1%" }}>
              Are you sure, you want to delete the Pricing Book?
            </h4>
            <DialogActions
              style={{
                marginLeft: "1vw",
                display: "flex",
                justifyContent: "row",
                marginRight: "5vw",
                marginTop: "1%",
                // marginBottom:'1vh',
                position: "fixed",
                // gap: "20px",
              }}
            >
              <List>
                <div
                  style={{
                    color: "#03AC13",
                    marginTop: "7%",
                    border: "2px solid #03AC13",
                    boxShadow: "white",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "7px",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  <ListItem
                    button
                    onClick={handlePriceDelClose}
                    style={{
                      paddingLeft: "30%",
                      marginTop: "-4%",
                      fontFamily:
                        "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Cancel
                  </ListItem>
                </div>
              </List>
              <List>
                <div
                  style={{
                    color: "#FFFFFF",
                    marginTop: "8%",
                    background: "red",
                    borderRadius: "8px",
                    width: "160px",
                    height: "40px",
                    padding: "3px",
                    fontSize: "15px",
                    marginLeft: "20px",
                    fontWeight: "600",
                  }}
                >
                  <ListItem
                    button
                    onClick={handleDeletePricingBook}
                    style={{
                      paddingLeft: "30%",
                      // marginTop: "4%",
                      fontFamily:
                        "normal normal bold 16px/21px Product Sans",
                    }}
                    color="secondary"
                    variant="outlined"
                  >
                    Proceed
                  </ListItem>
                </div>
              </List>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <ToastContainer
          toastStyle={{
            marginTop: "4rem",
            borderRadius: "10px",
            backgroundColor: "white",
            alignContent: "center",
            height: 50,
            margin: 10,
            width: 300,
            closeOnClick: true,
            textAlign: "center",
            position: "fixed",
            zIndex: 1050,
          }}
          closeButton={false}
          hideProgressBar={true}
          autoClose={500}
          position="top-center"
          className="toast-container"
          toastClassName="dark-toast"
        />
      </main>
    </div>
  </>);
}

export default App3;
