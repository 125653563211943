import React, { useEffect, useState, useContext } from 'react';
import axios from '../../../../../axios/index';
import AuthContext from '../../../../../hooks/useAuth';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ReferenceDot, ResponsiveContainer } from 'recharts';
import { ThemePreferenceContext } from '../../../../../index';

const AvgMemoryGraph = ({ currentTime, onPointClick }) => {
  const auth = useContext(AuthContext);
  const [data, setData] = useState([]);
  const userId = auth.id;
  const { currentTheme } = useContext(ThemePreferenceContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentSessionData = JSON.parse(sessionStorage.getItem("currentSessionData"));
        const response = await axios.get("/sessionDetails", {
          params: {
            userId: userId || currentSessionData.user_id,
            DeviceId: global.deviceid || currentSessionData.device_id,
            appName: global.appname || currentSessionData.app_name,
            sessionId: global.sessionid || currentSessionData.session_id,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        });

        const mappedData = response.data.data[0].avg_memory_usage.map((avg_mem, index) => ({
          avg_mem,
          // timestamp: formatTime(index + 1), 
          timestamp: (index * 2),
        }));
        console.log("Timestamp:", mappedData);
        setData(mappedData);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleClick = (e) => {
    if (e && e.activePayload) {
      onPointClick(e.activePayload[0].payload);
    }
  };

  const currentPoint = data.find((point) => point.timestamp === Math.floor(currentTime));

  console.log(currentPoint, "CURRENT POINT")
  const axisStyle = {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: 12,
    color: currentTheme === "dark" ? "#FFFFFF" : "#000000"
  };

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const seconds = time % 60;
  //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  // };

  const tooltipFormatter = (value, name, props) => {
    return [`${value}`, 'Mem Usage'];
  };

  return (
    <ResponsiveContainer width="100%" height={200} style={{ marginTop: "5%" }}>
       <LineChart data={data} onClick={handleClick} style={{cursor:"pointer"}}>
        <CartesianGrid stroke={currentTheme === "dark" ? "grey" : "#ccc"} />
        <XAxis dataKey="timestamp" label={{ value: 'Time(secs)', position: 'insideBottom', offset: -5, style: axisStyle }} />
        <YAxis label={{ value: 'Avg Mem Usage', angle: -90, position: 'insideLeftCenter', style: axisStyle }} />
        <Tooltip
          contentStyle={{ backgroundColor: "transparent", border: "none", borderRadius: 5, fontSize: "12px" }}
          formatter={tooltipFormatter}
        />
        <Line type="monotone" dataKey="avg_mem" stroke="#FEC400" />
        {currentPoint && (
          <ReferenceDot
            key={currentPoint.timestamp}
            x={currentPoint.timestamp}
            y={currentPoint.avg_mem}
            r={4}
             fill="orange"
            stroke="none"
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AvgMemoryGraph;
